import { useEffect, useMemo } from "react";

import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Typography } from "@mui/material";

import { ControlledTextField, Form } from "@work4Labs/design-system";

import { SettingsPageForm } from "@components";
import { loadTranslations } from "@lib";

import { yupResolver } from "@hookform/resolvers/yup";

const useOrganizationValidationSchema = () => {
  const { t } = useTranslation(["organization-validation"]);
  loadTranslations("organization-validation");

  return useMemo(
    () =>
      yup.object().shape({
        request_url: yup.string().url(t("organization-validation:requestURL.invalid")),
      }),
    [t],
  );
};

interface OrganizationFormData {
  request_url: string;
  organization_name: string;
}

export const OrganizationTab = () => {
  const { t } = useTranslation(["organization-settings"]);
  loadTranslations("organization-settings");

  const { data: session } = useSession();

  const validationSchema = useOrganizationValidationSchema();

  const organizationForm = useForm<OrganizationFormData>({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: "onChange",
    defaultValues: {
      request_url: "",
      organization_name: "",
    },
  });

  const organizationName = useMemo(() => session?.user?.groups?.[0] ?? "", [session]);

  useEffect(() => {
    if (organizationName) {
      organizationForm.setValue("organization_name", organizationName);
    }
  }, [organizationForm, organizationName]);

  return (
    <SettingsPageForm title={t("organization-settings:organization.title")}>
      <Form
        style={{ display: "flex", flexDirection: "column", gap: "var(--size-24)", alignItems: "stretch" }}
        submitHandler={() => null}
        methods={organizationForm}
      >
        <ControlledTextField
          label={t("organization-settings:organization.organizationName")}
          name="organization_name"
          disabled
          fullWidth
        />
        {organizationForm.formState.errors.root ? (
          <Typography color="var(--color-palette-alert-red-400)">
            {organizationForm.formState.errors.root.message}
          </Typography>
        ) : null}
      </Form>
    </SettingsPageForm>
  );
};
