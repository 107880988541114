import { useCallback, useMemo } from "react";

import { isNumber } from "lodash-es";
import { useTranslation } from "next-i18next";

import { ApplicationScoreGetterToLabel } from "@components";

import { useScoringQueries } from "@hooks/queries";

const useScoringRanks = () => {
  const { t } = useTranslation(["application-scoring"]);

  return useMemo(
    () => ({
      top_candidate: {
        label: t("top_candidate", { ns: "application-scoring" }),
        rank: 4,
      },
      high: {
        label: t("high", { ns: "application-scoring" }),
        rank: 3,
      },
      medium: {
        label: t("medium", { ns: "application-scoring" }),
        rank: 2,
      },
      low: {
        label: t("low", { ns: "application-scoring" }),
        rank: 1,
      },
      no_score: {
        label: t("no_score", { ns: "application-scoring" }),
        rank: 0,
      },
    }),
    [t],
  );
};

export const useScoringRowParams = (campaignIDs: string[]) => {
  const scoringRanks = useScoringRanks();

  const scorePerApplication = useScoringQueries(...campaignIDs);

  const getRowParams = useCallback<(applicationID: string) => { label: string; rank: number }>(
    (applicationID: string) => {
      if (scorePerApplication == null) return scoringRanks.no_score;

      const score: number | null = isNumber(scorePerApplication[applicationID])
        ? scorePerApplication[applicationID]
        : null;
      return scoringRanks[ApplicationScoreGetterToLabel(score)] ?? scoringRanks.no_score;
    },
    [scorePerApplication, scoringRanks],
  );

  return { getRowParams };
};
