import { Context } from "@opentelemetry/api";

import { withSpan, withTraceParent } from "@utils";

import { scoringAPI } from "./api";

// check if a campaign has a scoring engine set up
const hasEngine = async (ctx: Context, campaignID: string): Promise<boolean> =>
  withSpan(ctx, "HasScoringEngine", async () => {
    const res = await scoringAPI.get(`/${campaignID}/engine/exists`, withTraceParent()).catch((err) => {
      // Catch 404 errors.
      if (err.response.status === 404) {
        return { status: 404 };
      }
      throw err;
    });

    return res.status === 204;
  });

// retrieve the score of an application
const getScore = async (ctx: Context, applicationID: string): Promise<number> =>
  withSpan(ctx, "GetScore", async () => {
    const { data } = await scoringAPI.get(`/${applicationID}/score`, withTraceParent());
    return data?.score;
  });

// return a map of application_id: score for the campaign.
const getAllScores = async (ctx: Context, campaignID: string): Promise<Record<string, number>> =>
  withSpan(ctx, "GetAllScores", async () => {
    const { data } = await scoringAPI.get(`/${campaignID}/applications_score`, withTraceParent());
    return data;
  });

// recomputes the score of an application
const recomputeScore = async (ctx: Context, applicationID: string): Promise<void> =>
  withSpan(ctx, "RecomputeScores", async () => {
    await scoringAPI.post(`/${applicationID}/score`, {}, withTraceParent());
  });

export const ScoringAPI = {
  hasEngine,
  getAllScores,
  getScore,
  recomputeScore,
};
