import { Context } from "@opentelemetry/api";

import { Feature, Organization, ToggleFeatureParams } from "@typings";
import { withSpan, withTraceParent } from "@utils";

import { organizationsAPI } from "./api";

export interface UpdateOrganizationParams {
  body: {
    phone: string;
  };
}

const update = (ctx: Context, params: UpdateOrganizationParams): Promise<void> =>
  withSpan(ctx, "UpdateOrganization", async () => organizationsAPI.put("/me", params.body, withTraceParent()));

const getMy = async (ctx: Context): Promise<Organization> =>
  withSpan(ctx, "GetMyOrganization", async () => {
    const { data } = await organizationsAPI.get("/me", withTraceParent());
    return data;
  });

const enableFeature = (ctx: Context, params: ToggleFeatureParams): Promise<void> =>
  withSpan(ctx, "EnableOrganizationFeature", async () => {
    return organizationsAPI.post(`/${params.groupID}/features/${params.name}`, withTraceParent());
  });

const disableFeature = (ctx: Context, params: ToggleFeatureParams): Promise<void> =>
  withSpan(ctx, "DisableOrganizationFeature", async () => {
    return organizationsAPI.delete(`/${params.groupID}/features/${params.name}`, withTraceParent());
  });

const listFeatures = async (ctx: Context, groupID: string): Promise<Feature[]> =>
  withSpan(ctx, "ListOrganizationFeatures", async () => {
    const { data } = await organizationsAPI.get(`/${groupID}/features`, withTraceParent());
    return data;
  });

export const OrganizationsAPI = {
  getMy,
  update,
  enableFeature,
  disableFeature,
  listFeatures,
};
