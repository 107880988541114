import { FC, useEffect, useRef, useState } from "react";

import { MultipleStatusesSelector } from "components/common";
import { ObjectFieldTemplate } from "components/rjsf";
import { merge } from "lodash-es";
import { useTranslation } from "next-i18next";
import { Controller } from "react-hook-form";

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme,
} from "@mui/material";
import { ThemeOptions } from "@mui/system";

import { context } from "@opentelemetry/api";

import { useQuery } from "@tanstack/react-query";

import { AtsAPI } from "@api";
import { ATS_WORKFLOWS, LOCALE_OPTIONS, QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { AtsIntegration } from "@typings";

import { useATSIntegrationSetting } from "@hooks";
import { useApplicationStatuses } from "@hooks/queries";
import Form from "@rjsf/core";
import { Form as MuiForm } from "@rjsf/mui";
import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";

type Props = {
  atsIntegration?: AtsIntegration;
  campaignId: string;
};

const exportTabTheme: Partial<ThemeOptions> = {
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        InputLabelProps: {
          shrink: true,
        },
        InputProps: {
          notched: false,
        },
      },
    },
  },
};

export const AtsExportTab: FC<Props> = ({ atsIntegration, campaignId }) => {
  const { t } = useTranslation(["ats-export"]);
  loadTranslations("ats-export");

  const [selectedWorkflow, setSelectedWorkflow] = useState(ATS_WORKFLOWS.FORM_EXPORTER);

  const theme = useTheme();

  const formRef = useRef<Form<unknown, RJSFSchema>>(null);

  const statusesQuery = useApplicationStatuses();

  const jsonSchemaQuery = useQuery({
    queryKey: [QUERY_KEYS.EXPORTERS, selectedWorkflow, "json"],
    queryFn: () => AtsAPI.getExporterJSONSchema(context.active(), encodeURIComponent(selectedWorkflow)),
    enabled: !!selectedWorkflow,
  });

  const uiSchemaQuery = useQuery({
    queryKey: [QUERY_KEYS.EXPORTERS, selectedWorkflow, "ui"],
    queryFn: () => AtsAPI.getExporterUISchema(context.active(), encodeURIComponent(selectedWorkflow)),
    enabled: !!selectedWorkflow,
  });

  const atsSettings = useATSIntegrationSetting({ atsIntegration, campaignId, workflowPath: selectedWorkflow });

  useEffect(() => {
    if (atsIntegration) {
      setSelectedWorkflow(atsIntegration.workflow_path);
    }
  }, [atsIntegration]);

  return (
    <ThemeProvider theme={() => createTheme(merge(theme, exportTabTheme))}>
      <Typography variant="h5">{t("title", { ns: "ats-export" })}</Typography>
      <hr />
      <Card sx={{ marginBottom: "1rem" }}>
        <CardContent>
          <Box>
            <Typography variant="h5">{t("target_statuses.title", { ns: "ats-export" })}</Typography>
            <hr />
            <Controller
              name="status_ids"
              control={atsSettings.form.control}
              render={({ field }) => (
                <MultipleStatusesSelector
                  multiple
                  shrink
                  notched={false}
                  statuses={statusesQuery?.data || []}
                  value={field.value}
                  onSelectedStatusesChange={field.onChange}
                  error={atsSettings.form.errors.status_ids?.message}
                  label={t("target_statuses.label", { ns: "ats-export" })}
                  description={t("target_statuses.description", { ns: "ats-export" })}
                />
              )}
            />
          </Box>
          <Box mt={"1rem"}>
            <Typography variant="h5">{t("resume_language.title", { ns: "ats-export" })}</Typography>
            <hr />
            <Controller
              name="lang"
              control={atsSettings.form.control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="lang-label" variant="outlined" shrink>
                    {t("resume_language.label", { ns: "ats-export" })}
                  </InputLabel>
                  <Select
                    labelId="lang-label"
                    id="lang-selector"
                    notched={false}
                    label={t("resume_language.label", { ns: "ats-export" })}
                    value={field.value}
                    onChange={(event) => field.onChange(event.target.value)}
                  >
                    {LOCALE_OPTIONS.map((locale) => (
                      <MenuItem key={locale.value} value={locale.value}>
                        {t(locale.label)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Box>
          <Box mt={"1rem"}>
            <Typography variant="h5">{t("workflow.title", { ns: "ats-export" })}</Typography>
            <hr />
            <InputLabel id="workflow-label" variant="outlined" shrink>
              {t("workflow.label", { ns: "ats-export" })}
            </InputLabel>
            <Select
              fullWidth
              labelId="workflow-label"
              id="workflow-selector"
              notched={false}
              value={selectedWorkflow}
              onChange={(event) => setSelectedWorkflow(event.target.value)}
            >
              {Object.entries(ATS_WORKFLOWS).map(([workflowName, workflowPath]) => (
                <MenuItem key={workflowName} value={workflowPath}>
                  {t(`workflow.options.${workflowName.toLowerCase()}`, { ns: "ats-export" })}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </CardContent>
      </Card>

      <MuiForm
        noHtml5Validate
        ref={formRef}
        schema={jsonSchemaQuery.data || {}}
        templates={{ ObjectFieldTemplate }}
        uiSchema={uiSchemaQuery.data}
        validator={validator}
        onSubmit={(data) => {
          atsSettings.upsert(data, formRef.current?.validateForm()).catch();
        }}
        onChange={(data) => atsSettings.form.setData(data.formData)}
        formData={atsSettings.form.data}
      >
        <div style={{ float: "right", display: "flex", flexDirection: "row", gap: "0.4rem" }}>
          <Button variant="contained" color="primary" type="submit" aria-label="submit-arialabel">
            {t("submit", { ns: "ats-export" })}
          </Button>

          <Button
            disabled={!atsIntegration}
            onClick={atsSettings.delete}
            variant="contained"
            color="error"
            type="button"
            aria-label="deactivate-arialabel"
          >
            {t("deactivate", { ns: "ats-export" })}
          </Button>
        </div>
      </MuiForm>
    </ThemeProvider>
  );
};
