import { FC, useCallback, useMemo } from "react";

import { ApplicationActions } from "components/application-details/application-actions";
import { useTranslation } from "next-i18next";

import { Email as EmailIcon, Phone as PhoneIcon } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";

import { Context, context } from "@opentelemetry/api";

import { useQueryClient } from "@tanstack/react-query";

import { ApplicationScoringChip, EmailBox, SelectApplicationStatus, updateApplicationStatus } from "@components";
import { TEXT_COLOR } from "@constants";
import { computeApplicationJobLocation, loadTranslations } from "@lib";
import { Application, ApplicationStatusUpdateParams, Candidate, SourcingCampaign, Status } from "@typings";
import { Logger, parsePhoneNumber, startSpan } from "@utils";

import { useInterviewConfigurationByCampaignID } from "@hooks/queries";

import { HeaderContent } from "./styles";

/**
 * Hook to update the application status.
 */
export const useApplicationStatusUpdater = (applicationID: string) => {
  const queryClient = useQueryClient();

  return useCallback(
    async (ctx: Context, newStatus: Status | null, params: ApplicationStatusUpdateParams) => {
      await updateApplicationStatus({ applicationID, queryClient, newStatus, updateParams: params, ctx });
    },
    [applicationID, queryClient],
  );
};

type ApplicationNameStatusProps = {
  sourcingCampaign?: SourcingCampaign;
  candidate: Candidate;
  application: Application;
  applicationScore: number | undefined;
};

export const ApplicationNameStatus: FC<ApplicationNameStatusProps> = ({
  sourcingCampaign,
  candidate,
  application,
  applicationScore,
}) => {
  const { t } = useTranslation(["localization"]);
  loadTranslations("localization");

  const { data: interviewConfiguration } = useInterviewConfigurationByCampaignID(sourcingCampaign?.campaign_id);

  const onStatusChange = useApplicationStatusUpdater(application.id);

  const campaignIDs = useMemo(
    () => (sourcingCampaign?.campaign_id ? [sourcingCampaign.campaign_id] : []),
    [sourcingCampaign?.campaign_id],
  );

  return (
    <Grid sx={{ marginTop: "1rem" }} id="application_header">
      <HeaderContent>
        <Grid container spacing={2}>
          <Grid item lg={12} xl={4} display="flex" gap={(theme) => theme.spacings[16]} flexDirection="column">
            {(applicationScore ?? 0) >= 90 && sourcingCampaign?.campaign_id && (
              <Box>
                <ApplicationScoringChip
                  applicationID={application.id}
                  campaignID={sourcingCampaign.campaign_id}
                  renderChipForLowPercentages={false}
                  chipSx={{
                    borderRadius: "3px",
                    width: "138px",
                    height: "26px",
                  }}
                />
              </Box>
            )}

            <Box>
              <Typography color={TEXT_COLOR.mainInfo} variant="headingLG">
                {candidate.first_name} {candidate.last_name}
              </Typography>
            </Box>
          </Grid>

          <Grid item lg={12} xl={8} order={{ xl: 2, lg: 3 }}>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              alignItems="end"
              sx={(theme) => ({ gap: { md: theme.spacings[16], lg: theme.spacings[48] } })}
            >
              <SelectApplicationStatus
                applications={[application]}
                campaignIDs={campaignIDs}
                // onChange, update the status in the back
                onChange={(newStatus, params) => {
                  const cs = startSpan(context.active(), "UpdateApplicationStatus(FocusMode)");
                  onStatusChange(cs.ctx, newStatus, params)
                    .catch(Logger.error)
                    .finally(() => cs.span.end());
                }}
                sendSMS={true}
              />
            </Box>
          </Grid>
          <Grid item lg={12} xl={4} order={{ xl: 3, lg: 2 }}>
            <Box display="flex" flexDirection="column" gap={(theme) => theme.spacings[4]}>
              <Typography variant="bodyCopySmall" color={TEXT_COLOR.mainInfo}>
                {application.job_title}
                {" | "}
                {computeApplicationJobLocation(application, t("localization:missing"))}
              </Typography>
              <Typography variant="bodyCopySmall" color={TEXT_COLOR.mainInfo}>
                <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                  <PhoneIcon sx={(theme) => ({ height: 20, width: 20, fill: theme.palette.color.BASE[500] })} />
                  <Box> {parsePhoneNumber(candidate.phone)}</Box>
                </Box>
              </Typography>
              <Typography variant="bodyCopySmall" color={TEXT_COLOR.mainInfo}>
                <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                  <EmailIcon sx={(theme) => ({ height: 20, width: 20, fill: theme.palette.color.BASE[500] })} />
                  <EmailBox sx={(theme) => ({ fontSize: "14px", color: theme.palette.grey[800] })}>
                    {candidate.email}
                  </EmailBox>
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={12} xl={8} order={{ xs: 4 }} display="flex" justifyContent="flex-end">
            <ApplicationActions
              application={application}
              candidate={candidate}
              interviewConfiguration={interviewConfiguration}
            />
          </Grid>
        </Grid>
      </HeaderContent>
    </Grid>
  );
};
