import { useRouter } from "next/router";

import { useMemo } from "react";

import { useTranslation } from "next-i18next";

import { Box, Button, Chip, Typography } from "@mui/material";

import { context } from "@opentelemetry/api";

import { useQuery } from "@tanstack/react-query";

import { JobsApi } from "@api";
import { MyJobsCard, NoResults, Spinner } from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { JobStatus } from "@typings";

import { useUserGroup } from "@hooks";

interface Props {
  assignedJobs: string[];
}

export const MyJobs = ({ assignedJobs }: Props) => {
  const { t } = useTranslation(["my-jobs"]);
  loadTranslations("my-jobs");

  const router = useRouter();

  const userGroup = useUserGroup();

  const jobsQuery = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.JOBS, assignedJobs],
    queryFn: () =>
      JobsApi.search(context.active(), {
        job_id: assignedJobs,
        organization_name: userGroup,
        status: [JobStatus.ACTIVE, JobStatus.INACTIVE],
      }),
    enabled: assignedJobs.length > 0,
  });

  // sort jobs by title and location alphabetically and get only first 5 jobs
  const sortedJobs = useMemo(() => {
    return (
      jobsQuery.data
        ?.filter((job) => assignedJobs.includes(job.id))
        ?.sort((a, b) => {
          const titleCmp = a.title.toLowerCase().localeCompare(b.title.toLowerCase());
          if (titleCmp !== 0) return titleCmp;

          return (a.location_state?.toLowerCase() ?? "").localeCompare(b.location_state?.toLowerCase() ?? "");
        })
        .slice(0, 5) || []
    );
  }, [assignedJobs, jobsQuery.data]);

  if (jobsQuery.isLoading) {
    return <Spinner style={{ position: "relative" }} />;
  }

  if ((jobsQuery.isError || !jobsQuery.isSuccess) && jobsQuery.error !== null) {
    throw jobsQuery.error;
  }

  return (
    <Box display="flex" flexDirection="column" gap={(theme) => theme.spacings[16]}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box display="flex" flexDirection="row" gap={(theme) => theme.spacings[16]} alignItems="center">
          <Typography variant="headingLG">{t("title")}</Typography>
          <Chip label={assignedJobs.length} size="small" color="deepPurple" />
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="deepPurple"
            disabled={assignedJobs.length === 0}
            onClick={() =>
              router.push({
                pathname: "/jobs",
                query: {
                  offers_category: "my_offers",
                },
              })
            }
          >
            {t("show_all_my_jobs")}
          </Button>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={(theme) => theme.spacings[16]}>
        {sortedJobs.length > 0 ? (
          sortedJobs.map((job) => <MyJobsCard key={job.id} job={job} />)
        ) : (
          <NoResults
            onClick={() => router.push({ pathname: "/jobs", query: { offers_category: "all" } })}
            label={t("no_results.label")}
            buttonLabel={t("no_results.button_label")}
          />
        )}
      </Box>
    </Box>
  );
};
