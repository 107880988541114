import { Context } from "@opentelemetry/api";

import { Anonyme, AuthProxyUser, AuthProxyUsersList, Candidate } from "@typings";
import { withSpan, withTraceParent } from "@utils";

import { candidatesAPI, usersAPI } from "./api";

const getUser = async (ctx: Context, id: string): Promise<AuthProxyUser> =>
  withSpan(ctx, "GetUser", async () => {
    const { data } = await usersAPI.get(`/${id}`, withTraceParent());
    return data;
  });

const listUsers = async (ctx: Context, organization_name: string): Promise<AuthProxyUsersList> =>
  withSpan(ctx, "ListUsers", async () => {
    const { data } = await usersAPI.get(
      "/",
      withTraceParent({
        params: { organization_name },
      }),
    );
    return data;
  });

const getCandidate = async (ctx: Context, id: string): Promise<Candidate> =>
  withSpan(ctx, "GetCandidate", async () => {
    if (id === "") {
      return Anonyme;
    }

    const { data } = await candidatesAPI.get(`/${id}`, withTraceParent());
    return data;
  });

const searchCandidates = async (ctx: Context, ids: Readonly<Array<string>>): Promise<Candidate[]> =>
  withSpan(ctx, "SearchCandidates", async () => {
    const { data } = await candidatesAPI.post("/search", ids, withTraceParent());
    return data;
  });

export const AuthProxyAPI = {
  getUser,
  listUsers,
  getCandidate,
  searchCandidates,
};
