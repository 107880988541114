import { cloneDeep } from "lodash-es";

import { Context } from "@opentelemetry/api";

import { QueryClient, useQuery } from "@tanstack/react-query";

import { ApplicationApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { Application, JobStatus } from "@typings";
import { Logger } from "@utils";

import { useParseApplicationsList } from "./utils";

export const useFetchCampaignApplicationsList = (
  ctx: Context,
  organizationName: string,
  campaignID: string | undefined,
) => {
  const applicationsQuery = useQuery<Application[], unknown>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.APPLICATIONS_LIST, { campaignID }],
    queryFn: () => ApplicationApi.list(ctx, { campaign_id: campaignID }),
    enabled: !!campaignID,
  });

  return useParseApplicationsList(applicationsQuery, {
    organization_name: organizationName,
    // campaign_id: [campaignID ?? ""], temporary fix for the issue with the query until we fix the link between the campaign and the jobs
    status: [JobStatus.ACTIVE, JobStatus.INACTIVE],
  });
};

export const useFetchJobApplicationsList = (ctx: Context, organizationName: string, jobID: string | undefined) => {
  const applicationsQuery = useQuery<Application[], unknown>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.APPLICATIONS_LIST, { jobID }],
    queryFn: () => ApplicationApi.list(ctx, { job_id: jobID, campaign_id: "" }),
    enabled: !!jobID,
  });

  return useParseApplicationsList(applicationsQuery, {
    organization_name: organizationName,
    job_id: [jobID ?? ""],
    status: [JobStatus.ACTIVE, JobStatus.INACTIVE],
  });
};

export type PartialApplication = Partial<Omit<Application, "id">> & {
  id: string;
};

export const updateApplicationsCache = (
  queryClient: QueryClient,
  updater: PartialApplication | string | (PartialApplication | string)[],
) => {
  const cachedApplications = queryClient.getQueriesData<Application[]>({
    predicate: (query) => query.queryKey[0] === QUERY_KEYS.APPLICATIONS_LIST,
  });

  cachedApplications.forEach(([queryKey, applications]) => {
    if (!applications) return;

    const parsedNewApplications = Array.isArray(updater) ? updater : [updater];

    // Check if new applications belong to this query.
    parsedNewApplications.forEach((newApplication) => {
      if (typeof newApplication === "string") {
        queryClient.removeQueries({
          queryKey: [QUERY_KEYS.APPLICATIONS, newApplication],
        });
        const newApplications = applications.filter((application) => application.id !== newApplication);
        queryClient.setQueryData(queryKey, newApplications);
        return;
      }

      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.APPLICATIONS, newApplication.id],
        })
        .catch(Logger.error);

      const matchingApplicationIndex = applications.findIndex((application) => application.id === newApplication.id);
      if (matchingApplicationIndex === -1) return;

      const newApplications = [...applications];
      newApplications[matchingApplicationIndex] = Object.assign(
        cloneDeep(newApplications[matchingApplicationIndex]),
        newApplication,
      );
      queryClient.setQueryData(queryKey, newApplications);
    });
  });
};
