import { AxiosError } from "axios";
import { signIn } from "next-auth/react";
import { TFunction } from "next-i18next";
import { toast } from "react-toastify";

import { context } from "@opentelemetry/api";

import { useMutation } from "@tanstack/react-query";

import { UpdateUserInfoBody, userApi } from "@api";
import { Logger } from "@utils";

export const useUpdateUserInfo = (t: TFunction) =>
  useMutation<void, AxiosError, UpdateUserInfoBody>({
    mutationFn: (data: UpdateUserInfoBody) => userApi.updateUserInfo(context.active(), data),
    onSuccess: async () => {
      await signIn("keycloak", { callbackUrl: "/personal-settings" }).catch(Logger.error);
      toast.success(t("profile-settings:update.success").toString());
    },
    onError: (err) => {
      Logger.error(err);
      toast.error(t("profile-settings:update.error").toString());
    },
  });
