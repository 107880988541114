import Image from "next/image";
import { useRouter } from "next/router";

import { FC, useMemo } from "react";

import { useTranslation } from "next-i18next";

import { Box, Grid, Typography } from "@mui/material";

import { context } from "@opentelemetry/api";

import { useQuery } from "@tanstack/react-query";

import { Card } from "@work4Labs/design-system";

import { ApplicationApi } from "@api";
import { Spinner } from "@components";
import { APPLICATION_STATUSES, QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { Pie } from "@nivo/pie";
import { ApplicationsPerStatuses, ApplicationsReport } from "@typings";

import { animated } from "@react-spring/web";

const statusesBreakdownOrderArray: string[] = [
  APPLICATION_STATUSES.NEW,
  APPLICATION_STATUSES.DID_NOT_ANSWER,
  APPLICATION_STATUSES.VALIDATION_PENDING,
  APPLICATION_STATUSES.INTERVIEW,
  APPLICATION_STATUSES.OFFER,
  APPLICATION_STATUSES.ACCEPTED,
  APPLICATION_STATUSES.REFUSED,
  APPLICATION_STATUSES.ADDED_TO_TALENT_POOL,
];

export const ReportingTab: FC = () => {
  const { t } = useTranslation(["application-status", "reporting"]);
  loadTranslations("reporting");
  loadTranslations("application-status");

  const router = useRouter();

  const { id } = router.query;

  const applicationsReportQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_REPORTS, id],
    queryFn: () => ApplicationApi.getReporting(context.active(), { campaign_id: id as string }),
  });

  const applicationsReport = useMemo<ApplicationsReport>(() => {
    if (applicationsReportQuery.isLoading || !applicationsReportQuery.isSuccess) {
      return {
        hired_applications_count: 0,
        qualified_applications_count: 0,
        applications_processed_count: 0,
        applications_processed_time: 0,
        applications_per_statuses: [],
        applications_hire_time: 0,
        applications_interview_time: 0,
      };
    }

    return {
      hired_applications_count: applicationsReportQuery.data.hired_applications_count || 0,
      qualified_applications_count: applicationsReportQuery.data.qualified_applications_count || 0,
      applications_processed_count: applicationsReportQuery.data.applications_processed_count || 0,
      applications_processed_time: applicationsReportQuery.data.applications_processed_time || 0,
      applications_per_statuses: applicationsReportQuery.data.applications_per_statuses || [],
      applications_hire_time: applicationsReportQuery.data.applications_hire_time || 0,
      applications_interview_time: applicationsReportQuery.data.applications_interview_time || 0,
    };
  }, [
    applicationsReportQuery.isLoading,
    applicationsReportQuery.isSuccess,
    applicationsReportQuery.data?.hired_applications_count,
    applicationsReportQuery.data?.qualified_applications_count,
    applicationsReportQuery.data?.applications_processed_count,
    applicationsReportQuery.data?.applications_processed_time,
    applicationsReportQuery.data?.applications_per_statuses,
    applicationsReportQuery.data?.applications_hire_time,
    applicationsReportQuery.data?.applications_interview_time,
  ]);

  const applicationsCount = useMemo<number>(() => {
    if (applicationsReportQuery.isLoading || !applicationsReportQuery.isSuccess) {
      return 0;
    }

    return applicationsReportQuery.data.applications_per_statuses?.reduce((res, a) => res + a.value, 0) || 0;
  }, [
    applicationsReportQuery.data?.applications_per_statuses,
    applicationsReportQuery.isLoading,
    applicationsReportQuery.isSuccess,
  ]);

  const applicationsPerStatusesToDisplay = useMemo<ApplicationsPerStatuses[]>(() => {
    if (applicationsReportQuery.isLoading || !applicationsReportQuery.isSuccess) {
      return [];
    }

    return (
      applicationsReportQuery.data.applications_per_statuses?.concat([
        {
          id: "being_processed",
          label: "being processed",
          value: applicationsReportQuery.data.applications_per_statuses?.reduce(
            (res, a) => res + (["validation pending", "offer", "interview"].includes(a.label) ? a.value : 0),
            0,
          ),
        },
      ]) || []
    );
  }, [
    applicationsReportQuery.data?.applications_per_statuses,
    applicationsReportQuery.isLoading,
    applicationsReportQuery.isSuccess,
  ]);

  if (applicationsReportQuery.isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spinner style={{ position: "relative", left: 0, top: 0 }} />
      </div>
    );
  }

  if (applicationsReportQuery.isError) {
    throw applicationsReportQuery.error;
  }

  if (!applicationsReportQuery.isSuccess) {
    throw new Error("error while loading reporting data");
  }

  if (applicationsReportQuery.data.applications_per_statuses?.filter((x) => x.value > 0).length === 0) {
    return (
      <Box>
        <Grid
          container
          direction="column"
          spacing={3}
          sx={{
            textAlign: "center",
            alignItems: "middle",
            marginTop: "120px",
          }}
        >
          <Grid item xs={12}>
            <Image src="/assets/images/reporting-empty-state.png" alt="no-data" width={433} height={239} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              className="--heading-lg"
              sx={{
                whiteSpace: "pre",
              }}
            >
              {t("reporting:no_data")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="--body-copy">{t("reporting:coming_up")}</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box>
      <Grid sx={{ marginTop: "0!important" }} container direction="row" spacing={6}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            sx={{
              "& .--tags": {
                margin: "4px 0 0 0",
                minHeight: "3rem",
                maxHeight: "4rem",
                textOverflow: "elipsis",
              },
              "& .--heading-xl": {
                marginBottom: "14px",
              },
              "& .MuiBox-root": {
                backgroundColor: "white",
              },
            }}
          >
            <Grid item xs={12}>
              <Typography className="--heading-lg">{t("reporting:your_recruitment_process")}</Typography>
            </Grid>
            <Grid item xs={2.4} aria-label={t("reporting:time_to_process")} role="region">
              <Card
                label={t("reporting:time_to_process")}
                value={applicationsReport.applications_processed_time}
                upperThreshold={2.1}
                lowerThreshold={-1}
                displayTooltip
                tooltipText={t("reporting:time_to_process_hover")}
              />
            </Grid>
            <Grid item xs={2.4} aria-label={t("reporting:processed_percentage")} role="region">
              <Card
                label={t("reporting:processed_percentage")}
                value={
                  Math.round(
                    ((100 * applicationsReport.applications_processed_count) /
                      applicationsReport.qualified_applications_count) *
                      10,
                  ) / 10
                }
                unit={"%"}
                lowerThreshold={80}
                displayTooltip
                tooltipText={t("reporting:processed_percentage_hover")}
              />
            </Grid>
            <Grid item xs={2.4} aria-label={t("reporting:time_to_interview")} role="region">
              <Card
                label={t("reporting:time_to_interview")}
                value={applicationsReport.applications_interview_time}
                upperThreshold={7.1}
                lowerThreshold={-1}
                displayTooltip
                tooltipText={t("reporting:time_to_interview_hover")}
              />
            </Grid>
            <Grid item xs={2.4} aria-label={t("reporting:time_to_hire")} role="region">
              <Card
                label={t("reporting:time_to_hire")}
                value={applicationsReport.applications_hire_time}
                upperThreshold={14.1}
                lowerThreshold={-1}
                displayTooltip
                tooltipText={t("reporting:time_to_hire_hover")}
              />
            </Grid>
            <Grid item xs={2.4} aria-label={t("reporting:hired_percentage")} role="region">
              <Card
                label={t("reporting:hired_percentage")}
                value={
                  applicationsReport.applications_processed_count > 0
                    ? Math.round(
                        // compute the %
                        ((100 * (applicationsReport.hired_applications_count || 0)) /
                          applicationsReport.applications_processed_count) *
                          // * 10 in order to round the float
                          10,
                      ) / 10
                    : 0
                }
                unit={"%"}
                lowerThreshold={-1}
                displayTooltip
                tooltipText={t("reporting:hired_percentage_hover")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className="--heading-lg">{t("reporting:at_what_point")}</Typography>
            </Grid>
            <Grid
              container
              spacing={4}
              sx={{
                marginTop: "20px",
                backgroundColor: "white",
                borderRadius: "16px",
                marginLeft: "0px",
                paddingBottom: "32px",
              }}
            >
              <Grid item xs={6}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography className="--heading-lg">
                      {t("reporting:received_applications", { count: applicationsCount })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        "& .MuiGrid-item": {
                          width: "100%",
                        },
                        "& p": {
                          marginRight: "0 !important",
                        },
                      }}
                    >
                      <Grid item xs={4}>
                        <Card
                          centerText
                          revertText
                          value={
                            applicationsPerStatusesToDisplay.find((e) => e.label === APPLICATION_STATUSES.NEW)?.value ||
                            0
                          }
                          label={t("reporting:new")}
                          lowerThreshold={-1}
                          displayTooltip
                          tooltipText={t("reporting:new_hover")}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Card
                          centerText
                          revertText
                          value={
                            applicationsPerStatusesToDisplay.find((e) => e.label === APPLICATION_STATUSES.ACCEPTED)
                              ?.value || 0
                          }
                          label={t("reporting:accepted")}
                          lowerThreshold={-1}
                          displayTooltip
                          tooltipText={t("reporting:accepted_hover")}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Card
                          centerText
                          revertText
                          value={
                            applicationsPerStatusesToDisplay.find((e) => e.label === "being processed")?.value || 0
                          }
                          label={t("reporting:being_processed")}
                          lowerThreshold={-1}
                          displayTooltip
                          tooltipText={t("reporting:being_processed_hover")}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Card
                          centerText
                          revertText
                          value={
                            applicationsPerStatusesToDisplay.find(
                              (e) => e.label === APPLICATION_STATUSES.DID_NOT_ANSWER,
                            )?.value || 0
                          }
                          label={t("reporting:did_not_answer")}
                          lowerThreshold={-1}
                          displayTooltip
                          tooltipText={t("reporting:did_not_answer_hover")}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Card
                          centerText
                          revertText
                          value={
                            applicationsPerStatusesToDisplay.find(
                              (e) => e.label === APPLICATION_STATUSES.ADDED_TO_TALENT_POOL,
                            )?.value || 0
                          }
                          label={t("reporting:added_to_talent_pool")}
                          lowerThreshold={-1}
                          displayTooltip
                          tooltipText={t("reporting:added_to_talent_pool_hover")}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Card
                          centerText
                          revertText
                          value={
                            applicationsPerStatusesToDisplay.find((e) => e.label === APPLICATION_STATUSES.REFUSED)
                              ?.value || 0
                          }
                          label={t("reporting:refused")}
                          lowerThreshold={-1}
                          displayTooltip
                          tooltipText={t("reporting:refused_hover")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2} direction="row">
                  <Grid item xs={12}>
                    <Typography className="--heading-lg">
                      {t("reporting:statuses_repartition", { count: applicationsCount })}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      marginTop: "14px",
                    }}
                  >
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={12}>
                        <Typography className="--body-copy-strong">
                          {t("reporting:applications", { count: applicationsCount })}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container direction="row" spacing={1}>
                          {applicationsReport.applications_per_statuses
                            ?.sort(
                              (a, b) =>
                                statusesBreakdownOrderArray.indexOf(a.label) -
                                statusesBreakdownOrderArray.indexOf(b.label),
                            )
                            .map((e) => (
                              <>
                                <Grid
                                  item
                                  xs={8}
                                  sx={{
                                    p: {
                                      textAlign: "left",
                                    },
                                  }}
                                >
                                  <Typography className="--body-copy-small">
                                    {t(e.label, { ns: "application-status" })}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    p: {
                                      textAlign: "right",
                                    },
                                  }}
                                >
                                  <Typography className="--body-copy-small">
                                    {Math.round((100 * e.value) / applicationsCount)}% | {e.value}
                                  </Typography>
                                </Grid>
                              </>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Pie
                      margin={{ left: 20, bottom: 20 }}
                      width={280}
                      height={280}
                      data={applicationsReport.applications_per_statuses || []}
                      colors={{ scheme: "blues" }}
                      innerRadius={0}
                      padAngle={2}
                      enableArcLinkLabels={false}
                      arcLabelsComponent={({ label }) => (
                        <animated.div style={{ pointerEvents: "none" }}>
                          <text
                            textAnchor="middle"
                            dominantBaseline="central"
                            fill="white"
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          >
                            {label}
                          </text>
                        </animated.div>
                      )}
                      arcLabelsSkipAngle={20}
                      arcLabelsRadiusOffset={0.55}
                      arcLabelsTextColor={{
                        from: "color",
                        modifiers: [["darker", 0.6]],
                      }}
                      tooltip={({ datum: { label, value } }) => (
                        <div
                          style={{
                            lineHeight: "0px",
                            color: "black",
                            background: "white",
                            fontSize: 16,
                            padding: "8px 16px",
                            border: "1px solid #F6F8FF",
                            borderRadius: 16,
                            boxShadow:
                              "6px 16px 7px rgba(0, 0, 0, 0.01), 3px 9px 6px rgba(0, 0, 0, 0.05), 2px 4px 4px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--text-color-low-info)",
                            }}
                          >
                            <strong>{value}</strong> <>{t(label as string, { ns: "application-status" })}</>
                          </Typography>
                        </div>
                      )}
                      isInteractive
                      activeOuterRadiusOffset={4}
                      legends={[]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
