import moment from "moment";

import { Context } from "@opentelemetry/api";

import { MessagingHistory, MessagingReminder, MessagingTrigger, RecruiterReminder } from "@typings";
import { withSpan, withTraceParent } from "@utils";

import { messagingAPI } from "./api";

interface GetHistoriesQueryParams {
  application_id: string;
}
interface GetRemindersQueryParams {
  application_id: string;
}

interface GetTriggersQueryParams {
  campaign_id: string;
}

const getHistories = async (ctx: Context, params: GetHistoriesQueryParams): Promise<Array<MessagingHistory>> =>
  withSpan(ctx, "GetHistories", async () => {
    const { data } = await messagingAPI.get(`/histories`, withTraceParent({ params }));
    return data;
  });

const getReminders = async (ctx: Context, params: GetRemindersQueryParams): Promise<Array<MessagingReminder>> =>
  withSpan(ctx, "GetReminders", async () => {
    const { data } = await messagingAPI.get(`/reminders`, withTraceParent({ params }));
    return data;
  });

const getTriggers = async (ctx: Context, params: GetTriggersQueryParams): Promise<Array<MessagingTrigger>> =>
  withSpan(ctx, "GetTriggers", async () => {
    const { data } = await messagingAPI.get(`/triggers`, withTraceParent({ params }));
    return data;
  });

interface ListRecruiterReminderQueryParams {
  applicationID: string;
}

interface RecruiterReminderData {
  recruiter_id: string;
  comment: string;
  date: string;
  time: string;
  created_at: string;
}

const listRecruiterReminder = async (
  ctx: Context,
  params: ListRecruiterReminderQueryParams,
): Promise<Array<RecruiterReminder>> =>
  withSpan(ctx, "ListRecruiterReminders", async () => {
    const { data } = await messagingAPI.get<RecruiterReminderData[]>(
      `/recruiter_reminders?application_id=${params.applicationID}`,
      withTraceParent(),
    );

    return data.map(
      (reminderData): RecruiterReminder => ({
        application_id: params.applicationID,
        recruiter_id: reminderData.recruiter_id,
        date: moment(reminderData.date),
        time: moment(reminderData.time, "HH:mm:ss"),
        comment: reminderData.comment,
        created_at: reminderData.created_at,
      }),
    );
  });

const createRecruiterReminder = async (ctx: Context, params: RecruiterReminder): Promise<void> =>
  withSpan(ctx, "CreateRecruiterReminder", async () => {
    const { data } = await messagingAPI.post(
      "/recruiter_reminders",
      {
        application_id: params.application_id,
        comment: params.comment,

        date: params.date.format("YYYY-MM-DD"),
        time: params.time.format("HH:mm"),
      },
      withTraceParent(),
    );

    return data;
  });

export const MessagingApi = {
  getHistories,
  getReminders,
  getTriggers,
  listRecruiterReminder,
  createRecruiterReminder,
};
