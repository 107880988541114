import { type ChangeEvent, useCallback, useEffect, useState } from "react";

import debounce from "lodash/debounce";
import { useTranslation } from "next-i18next";

import { PersonSearch as PersonSearchIcon } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";

import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useApplicationsSearchStore } from "@stores";
import { Logger } from "@utils";

import { useOTELContext, useOTELSpan, useUserGroup } from "@hooks";
import { useSearchApplicationsInOrganization } from "@hooks/queries";

export const SearchBar = () => {
  const { t } = useTranslation(["search-bar"]);
  loadTranslations("search-bar");

  const [inputValue, setInputValue] = useState<string>("");

  const queryClient = useQueryClient();

  const { ctx } = useOTELSpan(useOTELContext(), "applications-search-bar");

  const organizationName = useUserGroup();

  const setApplications = useApplicationsSearchStore((state) => state.setApplications);
  const setSearchBarFocus = useApplicationsSearchStore((state) => state.setSearchBarFocus);
  const setIsLoading = useApplicationsSearchStore((state) => state.setIsLoading);
  const setLimit = useApplicationsSearchStore((state) => state.setLimit);
  const limit = useApplicationsSearchStore((state) => state.limit);

  const { applications, refetch, isLoading } = useSearchApplicationsInOrganization(ctx, organizationName, {
    q: inputValue,
    limit: limit,
  });

  useEffect(() => {
    setIsLoading(isLoading);
    !isLoading && setApplications(applications);
  }, [applications, isLoading, setApplications, setIsLoading]);

  useEffect(() => {
    setLimit(10);
  }, [inputValue, setLimit]);

  useEffect(() => {
    if (inputValue.length >= 3) {
      queryClient
        .cancelQueries({ queryKey: [QUERY_KEYS.APPLICATIONS_SEARCH, { organizationName }] })
        .catch(Logger.error);
      refetch();
      setSearchBarFocus(true);
    } else {
      queryClient
        .resetQueries({ queryKey: [QUERY_KEYS.APPLICATIONS_SEARCH, { organizationName }] })
        .catch(Logger.error);
    }
  }, [inputValue, organizationName, queryClient, refetch, limit, setSearchBarFocus]);

  const debouncedChange = debounce(setInputValue, 300);

  const onFocus = () => {
    inputValue.length >= 1 && setSearchBarFocus(true);
  };

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      debouncedChange(e.target.value);
    },
    [debouncedChange],
  );

  return (
    <TextField
      id={"applications-search-bar"}
      hiddenLabel
      placeholder={t("placeholder")}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PersonSearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={onChange}
      sx={{ width: "31.25rem" }}
      onFocus={onFocus}
    />
  );
};
