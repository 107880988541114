import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";

import { FC, useMemo } from "react";

import { Spinner } from "components/common";
import { MRT_Table as MRTTable, type MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import { useTranslation } from "next-i18next";

import { ArrowBackSharp as ArrowBackSharpIcon, Portrait as PortraitIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import { Button } from "@work4Labs/design-system";

import { COLOR_PALETTE, SIZE, SPACING } from "@constants";
import { loadTranslations } from "@lib";
import { useApplicationsSearchStore } from "@stores";
import { type ApplicationLight } from "@typings";
import { Logger } from "@utils";

import filterEye from "../../public/assets/filter-eye.png";

export const ApplicationsSearchResults: FC<{
  applications: ApplicationLight[];
  isLoading: boolean;
}> = ({ applications, isLoading }) => {
  const { t } = useTranslation(["header", "search", "navigation"]);
  loadTranslations("header");
  loadTranslations("search");
  loadTranslations("navigation");

  const router = useRouter();

  const setSearchBarFocus = useApplicationsSearchStore((state) => state.setSearchBarFocus);
  const limit = useApplicationsSearchStore((state) => state.limit);
  const setLimit = useApplicationsSearchStore((state) => state.setLimit);

  const columns = useMemo<MRT_ColumnDef<ApplicationLight>[]>(
    () => [
      {
        accessorFn: (row) => `${row.candidate?.first_name} ${row.candidate?.last_name}`,
        header: t("search:name"),
      },
      {
        accessorFn: (row) => `${row.candidate?.email}`,
        header: t("search:email"),
      },
      {
        accessorFn: (row) => `${row.candidate?.phone}`,
        header: t("search:phone"),
      },
      {
        accessorFn: (row) => `${row.job?.title}`,
        header: t("search:job_title"),
      },
      {
        accessorFn: (row) => `${row.job?.city}`,
        header: t("search:job_location"),
      },
    ],
    [t],
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: applications,
    enableKeyboardShortcuts: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    getRowId: (originalRow) => originalRow.id,
    muiTableBodyRowProps: ({ row }) => ({
      hover: true,
      onClick: () => {
        setSearchBarFocus(false);
        router.push(`/applications/${row.original.id}`).catch(Logger.error);
      },
    }),
    muiTableProps: {
      sx: {
        border: "1px solid rgb(212, 216, 248)",
        borderCollapse: "separate",
      },
    },
    defaultColumn: {
      muiTableHeadCellProps: {
        className: "--body-copy",
        sx: {
          borderBottom: "1px solid rgb(212, 216, 248)",
          borderTop: "1px solid rgb(212, 216, 248)",
          color: COLOR_PALETTE.BASE[500],
        },
      },
      muiTableBodyCellProps: ({ cell }) => ({
        className: cell.column.getIsFirstColumn() ? "--tags-strong" : "--tags",
        sx: {
          borderBottom: "1px solid rgb(212, 216, 248)",
          borderTop: "1px solid rgb(212, 216, 248)",
          "&:hover": {
            cursor: "pointer",
          },
        },
      }),
    },
    renderEmptyRowsFallback: () => (
      <Stack
        id="no_results"
        direction="column"
        gap={(theme) => theme.spacings[24]}
        padding={(theme) => theme.spacings[16]}
        alignItems="center"
        height="100%"
        sx={{
          backgroundColor: "white",
        }}
      >
        <Image src={filterEye} alt="no-data" />
        <Typography variant="body" textAlign="center" whiteSpace="pre-line">
          {t("search:no_results")}
        </Typography>
        <Typography variant="body" textAlign="center" whiteSpace="pre-line">
          {t("search:change_query")}
        </Typography>
      </Stack>
    ),
  });

  return (
    <>
      <Head>
        <title>{t("header:search_results_head")}</title>
      </Head>

      <Stack
        flexDirection="row"
        gap={SPACING[8]}
        alignItems="center"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setSearchBarFocus(false);
        }}
        sx={{
          position: "absolute",
          top: "3%",
          left: "6%",
          zIndex: 1000,
        }}
      >
        <Typography fontSize="var(--font-size-100)" color="var(--color-palette-base-500)">
          <ArrowBackSharpIcon fontSize="inherit" style={{ display: "block", margin: "auto 0" }} />
        </Typography>
        <Typography fontSize="var(--font-size-100)" color="var(--color-palette-base-500)">
          {t("navigation:back")}
        </Typography>
      </Stack>

      <Stack
        sx={{
          padding: `${SIZE[40]} ${SIZE[24]} ${SIZE[40]}`,
        }}
        flexDirection="column"
        alignItems="start"
        gap={SPACING[8]}
      >
        <Stack direction="row" gap={SPACING[12]} alignItems="center">
          <PortraitIcon sx={{ width: SIZE[40], height: SIZE[40] }} />
          <Typography variant="headingXL">{t("search:title")}</Typography>
        </Stack>
        {isLoading ? (
          <Spinner style={{ position: "absolute", top: "30%" }} />
        ) : (
          <>
            <MRTTable table={table} />
            {limit !== undefined && applications.length === limit && (
              <Button label={t("search:see_all")} onClick={() => setLimit(undefined)} />
            )}
          </>
        )}
      </Stack>
    </>
  );
};
