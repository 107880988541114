import { FC, useMemo } from "react";

import { ApplicationDocuments } from "components/application/application-documents";
import { ApplicationNameStatus } from "components/application/application-name-status";
import { ApplicationQualification } from "components/application/application-qualification";
import { useApplicationWithJobInfo } from "hooks/queries/applications";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import { Chip, Stack } from "@mui/material";

import { context } from "@opentelemetry/api";

import { useQuery } from "@tanstack/react-query";

import { ApplicationApi, AuthProxyAPI } from "@api";
import { ApplicationInterview, BasicTab, CallInProgressAlert, CommentList, ItemTab, Spinner } from "@components";
import { COLOR_PALETTE, QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { ORGANIZATIONS_FEATURES } from "@typings";

import {
  useCampaign,
  useCurrentUserOrganization,
  useInterviewConfigurationByCampaignID,
  useOrganizationFeatures,
  useScoringQuery,
} from "@hooks/queries";

type ApplicationDetailsProps = {
  applicationID: string;
};

export const ApplicationDetails: FC<ApplicationDetailsProps> = ({ applicationID }) => {
  const { t } = useTranslation(["application-details"]);
  loadTranslations("application-details");

  const { data: session } = useSession();

  const { data: application } = useApplicationWithJobInfo(applicationID);

  const currentOrganizationQuery = useCurrentUserOrganization(session?.user?.id);

  const { hasFeature } = useOrganizationFeatures(currentOrganizationQuery.data?.group_id ?? "", {
    enabled: currentOrganizationQuery.data?.group_id !== undefined,
  });

  const candidateQuery = useQuery({
    queryKey: [QUERY_KEYS.CANDIDATES, application?.candidate_id],
    queryFn: () => AuthProxyAPI.getCandidate(context.active(), String(application?.candidate_id)),
    enabled: !!application,
  });

  const applicationDocumentsQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATION_DOCUMENTS, applicationID],
    queryFn: () => ApplicationApi.listDocuments(context.active(), applicationID),
  });

  const sourcingCampaignQuery = useCampaign(application?.campaign_id);

  const { data: applicationsScore } = useScoringQuery(application?.campaign_id);

  const { data: interviewConfiguration } = useInterviewConfigurationByCampaignID(application?.campaign_id);

  const applicationScore = useMemo(() => applicationsScore?.[applicationID], [applicationID, applicationsScore]);

  if (!candidateQuery.data || !application || !applicationDocumentsQuery.data) {
    return (
      <Stack flexDirection="row" flexGrow={1} alignSelf="stretch" role="presentation">
        <Spinner style={{ position: "relative" }} />
      </Stack>
    );
  }

  return (
    <Stack flexGrow={4} flexDirection="row" overflow="hidden" flexWrap="nowrap" alignSelf="stretch" role="presentation">
      <CallInProgressAlert />

      <Stack
        id="application_content"
        overflow="auto"
        flexGrow={1}
        alignSelf="stretch"
        borderLeft={(theme) => `1px solid ${theme.palette.color.pastelPurple}`}
      >
        <ApplicationNameStatus
          sourcingCampaign={sourcingCampaignQuery.data}
          candidate={candidateQuery.data}
          application={application}
          applicationScore={applicationScore}
        />
        <BasicTab
          style={{
            marginTop: "1.5rem",
            paddingLeft: "40px",
            overflowWrap: "anywhere",
          }}
          tabs={{
            [t("tabs.qualifications")]: {
              node: <ApplicationQualification application={application} />,
            } as ItemTab,
            [t("tabs.documents")]: {
              node: <ApplicationDocuments applicationID={application.id} candidate={candidateQuery.data} />,
              hidden: !hasFeature(ORGANIZATIONS_FEATURES.APPLICATIONS_DOCUMENTS),
              tag: applicationDocumentsQuery.data.length > 0 && (
                <Chip
                  label={applicationDocumentsQuery.data.length}
                  sx={{ backgroundColor: COLOR_PALETTE.deepPurple, color: "white" }}
                />
              ),
            } as ItemTab,
            [t("tabs.interviews")]: {
              node: sourcingCampaignQuery.data && (
                <ApplicationInterview application={application} interviewConfiguration={interviewConfiguration} />
              ),
              hidden: interviewConfiguration === undefined || !sourcingCampaignQuery.data,
            } as ItemTab,
          }}
        />
      </Stack>

      <Stack
        overflow="auto"
        flexGrow={1}
        flexShrink={0}
        alignSelf="stretch"
        paddingBottom="1rem"
        width="40rem"
        maxWidth="40vw"
        borderLeft={(theme) => `1px solid ${theme.palette.color.pastelPurple}`}
      >
        {application && <CommentList application={application}></CommentList>}
      </Stack>
    </Stack>
  );
};
