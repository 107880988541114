import { FC, useCallback, useEffect, useState } from "react";

import moment, { Moment } from "moment";
import { TFunction, Trans, useTranslation } from "next-i18next";
import { Controller, useForm } from "react-hook-form";
import "react-multi-email/dist/style.css";
import { toast } from "react-toastify";
import * as yup from "yup";

import { AddAlert as AddAlertIcon } from "@mui/icons-material";
import { Box, FormControl, FormHelperText, Grid, InputLabel, ThemeProvider, useTheme } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers-pro";

import { context } from "@opentelemetry/api";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { InfoBox, Modal } from "@work4Labs/design-system";

import { MessagingApi } from "@api";
import { Form, FormDatePicker, ReactQuill } from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { AriaProps, RecruiterReminder } from "@typings";

import { yupResolver } from "@hookform/resolvers/yup";

interface RecruiterReminderCreation {
  date: Moment | null;
  time: Moment | null;

  comment: string;
}

interface RecruiterReminderModalProps extends AriaProps {
  applicationID: string;
  initialComment: string;

  modalOpened: boolean;

  onSuccess: () => void;
  closeModal: () => void;
}

const getValidationSchema = (t: TFunction) => {
  return yup.object().shape({
    date: yup
      .date()
      .min(moment().set("hours", 23).set("minutes", 59), t("min_date"))
      // TypeError is required because it's possible that the value has been set to null before.
      .typeError(t("date_required"))
      .required(t("date_required")),
    time: yup
      .date()
      // TypeError is required because it's possible that the value has been set to null before.
      .typeError(t("time_required"))
      .required(t("time_required")),

    comment: yup.string().required(t("comment_required")),
  });
};

export const RecruiterReminderCreationModal: FC<RecruiterReminderModalProps> = ({
  applicationID,
  initialComment,
  modalOpened,
  closeModal,
  onSuccess,
}) => {
  const { t } = useTranslation(["recruiter-reminder-creation-modal", "dates"]);
  loadTranslations("recruiter-reminder-creation-modal");
  loadTranslations("dates");

  const theme = useTheme();

  const [comment, setComment] = useState(initialComment);

  const validationSchema = getValidationSchema(t);
  const reminderForm = useForm<RecruiterReminderCreation>({
    shouldUnregister: false,
    defaultValues: {
      comment: initialComment,
      date: null,
      time: moment().set("hours", 8).set("minutes", 0),
    },
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const queryClient = useQueryClient();
  const { mutate: createRecruiterReminder } = useMutation({
    mutationFn: (data: RecruiterReminder) => MessagingApi.createRecruiterReminder(context.active(), data),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.MESSAGING_RECRUITER_REMINDERS, applicationID],
        })
        .catch(() => {});

      toast.success(t("reminder_created"));
    },
    onError: () => {
      toast.error(t("reminder_fail_to_create"));
    },
  });

  const {
    reset,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = reminderForm;

  // control the comment input as it's handled by quill
  useEffect(() => {
    setComment(initialComment);
    setValue("comment", initialComment);
  }, [initialComment, setComment, setValue]);

  const onFormSubmit = useCallback(() => {
    // set the value in the form to let it handles errors
    setValue("comment", comment);

    trigger().then((isValid: boolean) => {
      if (isValid) {
        const values = getValues();

        createRecruiterReminder(
          {
            date: moment(values.date),
            time: moment(values.time),
            comment: comment,

            application_id: applicationID,
            // values created by the back, we just set them for TS
            created_at: "",
            recruiter_id: "",
          },
          {
            onSuccess: () => {
              onSuccess();
              closeModal();
              reset();
            },
          },
        );
      }
    });
  }, [setValue, comment, trigger, getValues, createRecruiterReminder, applicationID, onSuccess, closeModal, reset]);

  const onClose = useCallback(() => {
    reset();
    closeModal();
  }, [closeModal, reset]);

  return (
    <Modal
      isOpen={modalOpened}
      scroll={"body"}
      modalIcon={<AddAlertIcon />}
      title={t("title")}
      modalTitle={t("modal_title")}
      onConfirm={onFormSubmit}
      onClose={onClose}
      cancelText={t("cancel")}
      confirmText={t("submit")}
    >
      <ThemeProvider theme={theme}>
        <Form methods={reminderForm} submitHandler={() => {}}>
          <Grid container sx={{ paddingTop: "1rem" }}>
            <InfoBox title={""} level={"info"}>
              <Trans
                t={t}
                i18nKey="info_box"
                // eslint-disable-next-line react/jsx-key
                components={[<strong key={0} />]}
              />
            </InfoBox>
          </Grid>
          <Grid container sx={{ paddingTop: "1rem" }}>
            <Grid item xs={6} sx={{ paddingRight: "1rem" }}>
              <FormDatePicker
                fullWidth
                name="date"
                required
                minDate={moment().add(1, "days")}
                label={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <span>{t("date_label")}</span>
                  </Box>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth error={!!errors.time}>
                <InputLabel shrink htmlFor={"time"} sx={{ display: "list-item" }}>
                  {t("time_label")}
                </InputLabel>
              </FormControl>

              <Controller
                name={"time"}
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl fullWidth error={!!errors.time}>
                      <TimePicker
                        {...field}
                        value={moment(field.value)}
                        onChange={(value: Moment) => {
                          field.onChange(value);
                        }}
                        ampm={moment.locale().includes("en")}
                        slotProps={{ textField: { InputProps: { "aria-label": "time-recruiter-reminder" } } }}
                      />

                      {errors.time && <FormHelperText>{errors.time?.message}</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ paddingTop: "1rem" }}>
            <Grid item xs={12}>
              {t("comment_label")}
              <Box
                sx={{
                  backgroundColor: "white",
                  marginTop: "15px",
                  height: "150px",
                  marginBottom: "42px",
                }}
              >
                <ReactQuill
                  id="recruiter-reminder-comment-editor"
                  bounds="#recruiter-reminder-comment-editor"
                  style={{ backgroundColor: "white", height: "100%" }}
                  className="--body-copy"
                  theme="snow"
                  value={comment}
                  onChange={setComment}
                  modules={{
                    toolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["link"]],
                  }}
                />
              </Box>
              {errors.comment && <FormHelperText>{errors.comment?.message}</FormHelperText>}
            </Grid>
          </Grid>
        </Form>
      </ThemeProvider>
    </Modal>
  );
};
