import { FC, useCallback, useState } from "react";

import { useSession } from "next-auth/react";
import { Trans, useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Update as UpdateIcon } from "@mui/icons-material";
import { Button, Grid, MenuItem, ThemeProvider, Typography, useTheme } from "@mui/material";

import { context } from "@opentelemetry/api";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { InfoBox, Modal, Select } from "@work4Labs/design-system";

import { ApplicationApi, RequestDocumentsParams } from "@api";
import { COLOR_PALETTE, DOCUMENT_TYPES, QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";

interface Props {
  open: boolean;

  // a function to close the modal
  closeModal: () => void;

  applicationID: string;
}

export const RequestDocumentModal: FC<Props> = ({ open, closeModal, applicationID }) => {
  const { t } = useTranslation(["application-documents"]);
  loadTranslations("application-documents");

  const { data: session } = useSession();

  const [documentType, setDocumentType] = useState<DOCUMENT_TYPES>(DOCUMENT_TYPES.Resume);

  const theme = useTheme();

  const queryClient = useQueryClient();
  const { mutate: requestDocuments } = useMutation({
    mutationFn: (data: RequestDocumentsParams) => ApplicationApi.requestDocuments(context.active(), data),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.APPLICATIONS, applicationID],
        })
        .catch(() => {});

      toast.success(t("request_sent"));
      closeModal();
    },
    onError: () => {
      toast.error(t("request_failed"));
    },
  });

  const onConfirmButton = useCallback(() => {
    requestDocuments({
      application_id: applicationID,
      body: {
        document_type: documentType,
      },
    });
    closeModal();
  }, [applicationID, closeModal, documentType, requestDocuments]);

  const modalCustomButtons = useCallback(() => {
    const buttonStyle = {
      fontSize: "1rem",
      fontWeight: "600",
      textTransform: "none",
      borderRadius: "8px",
    };
    return (
      <>
        <Button
          variant="outlined"
          onClick={closeModal}
          sx={{
            ...buttonStyle,
            color: theme.palette.primary.main,
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "#F2F3F7",
            },
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="outlined"
          onClick={onConfirmButton}
          sx={{
            ...buttonStyle,
            marginRight: "0.5rem",
            color: "white",
            backgroundColor: theme.palette.color.deepPurple,
            "&:hover": {
              backgroundColor: theme.palette.color.deepPurple,
            },
          }}
        >
          {t("submit_request")}
        </Button>
      </>
    );
  }, [closeModal, onConfirmButton, t, theme]);

  return (
    <Modal
      isOpen={open}
      aria-label="application-documents-modal-request"
      aria-describedby="application-documents-modal-request"
      scroll="body"
      modalTitle={t("request_document_modal_title")}
      title={t("request_document_title")}
      modalIcon={<UpdateIcon />}
      onClose={closeModal}
      onConfirm={onConfirmButton}
      customActions={modalCustomButtons}
      options={{
        maxWidth: "450px",
      }}
    >
      <ThemeProvider theme={theme}>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Grid item xs={12}>
            <InfoBox title={""} level={"info"}>
              <Trans
                t={t}
                i18nKey="info_box"
                // eslint-disable-next-line react/jsx-key
                components={[<strong key={0} />]}
              />
            </InfoBox>
          </Grid>
          <Grid item xs={12}>
            <Select
              renderValue={(selected) => t(`document_type_${selected}`)}
              value={documentType}
              onChange={(e) => setDocumentType(e.target.value as DOCUMENT_TYPES)}
              required
              label={t("choose_documents")}
            >
              {Object.values(DOCUMENT_TYPES).map((kind) => (
                <MenuItem key={kind} value={kind}>
                  {t(`document_type_${kind}`)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="bodyCopy" sx={{ fontWeight: 700 }}>
              {t("sms_preview")}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ backgroundColor: COLOR_PALETTE.BASE[100], padding: 2 }}>
            <Typography>{t("sms_preview_content", { organization_name: session?.user?.groups?.[0] || "" })}</Typography>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Modal>
  );
};
