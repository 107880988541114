import { useCallback } from "react";

import { useUpdateUserInfo } from "hooks/queries";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";

import { ControlledTextField, Form } from "@work4Labs/design-system";

import { SettingsSaveButton } from "@components";
import { Locale, Roles } from "@constants";
import { loadTranslations } from "@lib";
import { hasRole } from "@utils";

import { LocaleSelector } from "./locale-selector";

export interface AccountParametersFormData {
  role: string;
  locale: string;
}

export const AccountParametersForm = () => {
  const { t } = useTranslation(["profile-settings", "common"]);
  loadTranslations("profile-settings");
  loadTranslations("common");

  const { data: session } = useSession();
  const role = t(
    `profile-settings:account.accountParameters.roles.${hasRole(session, Roles.ADMIN) ? Roles.ADMIN : Roles.RECRUITER}`,
  );

  const accountParametersForm = useForm<AccountParametersFormData>({
    shouldUnregister: false,
    defaultValues: {
      role: role,
      locale: session?.user.locale ?? Locale.EN,
    },
  });

  const watchLocale = accountParametersForm.watch("locale");

  const updateUserInfoMutation = useUpdateUserInfo(t);

  const updateUserPersonalInformation = useCallback(
    (formData: AccountParametersFormData) => {
      if (formData.locale !== session?.user.locale) {
        updateUserInfoMutation.mutate({
          locale: formData.locale,
        });
      }
    },
    [session?.user.locale, updateUserInfoMutation],
  );

  const canSave =
    session &&
    accountParametersForm.formState.isValid &&
    watchLocale !== session?.user.locale &&
    !updateUserInfoMutation.isPending;

  return (
    <Form
      style={{ display: "flex", flexDirection: "column", gap: "var(--size-24)", alignItems: "stretch" }}
      submitHandler={updateUserPersonalInformation}
      methods={accountParametersForm}
    >
      <ControlledTextField
        disabled
        label={t("profile-settings:account.accountParameters.role")}
        name="role"
        fullWidth
      />
      <LocaleSelector methods={accountParametersForm} />
      <SettingsSaveButton disabled={!canSave}>
        {t("profile-settings:account.personalInformation.save")}
      </SettingsSaveButton>
    </Form>
  );
};
