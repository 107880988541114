import { FC } from "react";

import { useTranslation } from "next-i18next";

import { Share as ShareIcon } from "@mui/icons-material";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Card, Grid, Typography } from "@mui/material";

import { context } from "@opentelemetry/api";

import { useQuery } from "@tanstack/react-query";

import { AuthProxyAPI } from "@api";
import { QUERY_KEYS, TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { ApplicationShare, AriaProps } from "@typings";

import { CommentListSubInfo, TimelinePerson } from "./style";

interface ApplicationShareDetailProps extends AriaProps {
  applicationShare: ApplicationShare;
}

const ApplicationShareCard: FC<ApplicationShareDetailProps> = ({ applicationShare }) => {
  const { t } = useTranslation(["application-share"]);
  loadTranslations("application-share");

  return (
    <Card
      sx={{ width: "100%", padding: "0.5rem", border: "1px solid #b6b9db", borderRadius: "0.5rem", boxShadow: "none" }}
    >
      <Grid item xs={12} sx={{ margin: "auto" }}>
        <Box>
          <ShareIcon sx={{ width: "24px", height: "24px", position: "relative", top: "3px" }} />
          <Typography variant="tagsStrong" sx={{ marginLeft: "8px", position: "relative", bottom: "4px" }}>
            {t("recipients")}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ margin: "auto", fontSize: "14px" }}>
        <Box sx={{ marginLeft: "32px" }}>{applicationShare.recipients.join(", ")}</Box>
      </Grid>
    </Card>
  );
};

export const ApplicationShareDetail: FC<ApplicationShareDetailProps> = ({ applicationShare, role }) => {
  const { t } = useTranslation(["application-share"]);
  loadTranslations("application-share");

  const { data: user, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.USERS, applicationShare.user_id],
    queryFn: () => AuthProxyAPI.getUser(context.active(), applicationShare.user_id),
  });

  return (
    <TimelineItem role={role}>
      <TimelineSeparator>
        <TimelinePerson userName={user ? `${user.first_name} ${user.last_name}` : undefined} userEmail={user?.email} />
        <TimelineConnector sx={(theme) => ({ color: theme.palette.grey[400] })} />
      </TimelineSeparator>
      <TimelineContent sx={{ position: "relative", top: "-18px !important" }}>
        {user && !isLoading && (
          <Box>
            <Box sx={{ color: TEXT_COLOR.mainInfo, fontSize: "14px" }}>
              <b>
                {user.first_name} {user.last_name}
              </b>{" "}
              {t("shared_application")}
            </Box>
            <ApplicationShareCard applicationShare={applicationShare} />
            <Box sx={{ paddingTop: "0.25rem" }}>
              <CommentListSubInfo created_at={applicationShare.created_at} kind={t("share_profile")} />
            </Box>
          </Box>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};
