import { FC, ReactNode } from "react";

import { context } from "@opentelemetry/api";

import { useQuery } from "@tanstack/react-query";

import { ClickToCallAPI } from "@api";
import { QUERY_KEYS } from "@constants";
import { TelnyxRTCProvider } from "@telnyx/react-client";

import { Spinner } from "./spinner";

interface Props {
  children?: ReactNode;
}

// This component is used to wrap the application to provide the TelnyxRTCProvider
// If we fail to get the token, the children will be rendered without the provider
export const TelnyxWrapper: FC<Props> = ({ children }) => {
  const tokenQuery = useQuery({
    queryKey: [QUERY_KEYS.CLICK_TO_CALL_TOKEN],
    queryFn: () => ClickToCallAPI.getToken(context.active()),
    // token validity is 24 hours
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60 * 24,
    retry: 3,
    retryDelay: 5000,
  });

  if (process.env.ENVIRONMENT === "dev") {
    return <>{children}</>;
  }

  if (tokenQuery.isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spinner style={{ position: "relative", left: 0, top: 0 }} />
      </div>
    );
  }

  if (tokenQuery.isError || !tokenQuery.isSuccess) {
    return <>{children}</>;
  }

  return (
    <TelnyxRTCProvider
      credential={{
        login_token: tokenQuery.data,
      }}
    >
      {children}
    </TelnyxRTCProvider>
  );
};
