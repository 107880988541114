import { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "next-i18next";
import Draggable from "react-draggable";

import { CallEnd as CallEndIcon, Person as PersonIcon } from "@mui/icons-material";
import { Box, Button, useTheme } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";

import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { Audio, useNotification } from "@telnyx/react-client";
import { Call as ICall } from "@telnyx/webrtc/lib/src/Modules/Verto/webrtc/Call";
import { Logger } from "@utils";

export const CallBox = () => {
  const { t } = useTranslation(["click-to-call"]);
  loadTranslations("click-to-call");

  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);

  const notification = useNotification();

  const call: ICall = useMemo(() => notification?.call, [notification?.call]);
  const applicantName = useMemo(
    () => call?.options.customHeaders?.find((h) => h.name === "X-Destionation-Name")?.value || "",
    [call?.options.customHeaders],
  );

  const applicationId = useMemo(
    () => call?.options.customHeaders?.find((h) => h.name === "X-Application-External-ID")?.value || "",
    [call?.options.customHeaders],
  );

  const callState = useMemo(() => call?.state, [call?.state]);

  const hangup = useCallback(() => {
    if (callState === "active" || callState === "early" || callState === "trying") {
      call?.hangup({}, true);
    } else {
      // if call state is not active, it means that the call is not established yet
      // so we can just hangup without executing the bye function
      call?.hangup({}, false);
    }
  }, [call, callState]);

  useEffect(() => {
    if (callState === "active") {
      const id = setInterval(() => {
        setCount((prev) => prev + 1000);
      }, 1000);
      return () => {
        setCount(0);
        clearInterval(id);
      };
    }

    if (callState === "hangup" || callState === "destroy" || callState === "purge") {
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    } else if (callState) {
      setOpen(true);
    }

    // each time the call state changes, we need to invalidate the query to update the history
    queryClient
      .invalidateQueries({
        queryKey: [QUERY_KEYS.CLICK_TO_CALL_APPLICATION_HISTORIES, applicationId],
      })
      .catch(Logger.error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callState]);

  const timer = useMemo(() => {
    const second = Math.floor((count / 1000) % 60)
      .toString()
      .padStart(2, "0");
    const minute = Math.floor((count / 1000 / 60) % 60)
      .toString()
      .padStart(2, "0");

    return minute + ":" + second;
  }, [count]);

  if (!open) {
    return null;
  }

  return (
    <>
      <Draggable
        bounds="body"
        defaultPosition={{
          x: 0,
          // eslint-disable-next-line no-mixed-operators
          y: document.documentElement.getBoundingClientRect().top * -1 + 50,
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.color.BASE[800],
          })}
          borderRadius={"1rem"}
          position="absolute"
          zIndex={9999}
          maxWidth="23rem"
          width="23rem"
        >
          <Box
            display="flex"
            zIndex={9999}
            flexDirection="column"
            padding="4rem"
            justifyContent="center"
            alignItems="center"
            gap="2.5rem"
          >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <CallIcon callState={callState} />
              <Box sx={{ color: "white", fontSize: "1.25rem", fontWeight: "700" }}>{applicantName}</Box>
              <Box sx={{ color: "white", opacity: 0.7 }}>
                {callState === "active" ? timer : t(callState, { ns: "click-to-call" })}
              </Box>
            </Box>

            <Button
              disabled={["new", "hangup", "destroy", "purge"].includes(callState)}
              variant="contained"
              color="error"
              onClick={() => hangup()}
              sx={(theme) => ({
                borderRadius: "1rem",
                width: "3.5rem",
                height: "3.5rem",
                "&.Mui-disabled": { backgroundColor: theme.palette.error.main },
              })}
            >
              <CallEndIcon />
            </Button>
          </Box>
        </Box>
      </Draggable>
      <Audio stream={call?.remoteStream} />
    </>
  );
};

const CallIcon = ({ callState }: { callState: string }) => {
  const theme = useTheme();

  const icon = useMemo(() => {
    switch (callState) {
      case "active":
        return {
          icon: PersonIcon,
          iconColor: "white",
          background: "#7BC27B",
        };
      case "hangup":
      case "destroy":
      case "purge":
        return {
          icon: CallEndIcon,
          iconColor: "white",
          background: "#E896A4",
        };
      default:
        return {
          icon: PersonIcon,
          iconColor: theme.palette.primary.main,
          background: "white",
        };
    }
  }, [callState, theme.palette.primary.main]);

  return (
    <Box
      sx={{
        borderRadius: "50%",
        width: "8rem",
        height: "8rem",
        background: icon.background,
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      my="1.25rem"
    >
      <icon.icon sx={{ fontSize: "4rem", color: icon.iconColor }} />
    </Box>
  );
};
