import { context } from "@opentelemetry/api";

import { useQueries } from "@tanstack/react-query";

import { MessagingApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { combineQueriesArrays } from "@utils";

export const useCampaignsTriggers = (...campaignIDs: string[]) =>
  useQueries({
    queries: campaignIDs.map((campaign_id) => ({
      queryKey: [QUERY_KEYS.MESSAGING_TRIGGERS, campaign_id],
      queryFn: () => MessagingApi.getTriggers(context.active(), { campaign_id }),
      enabled: !!campaign_id,
    })),
    combine: combineQueriesArrays((trigger) => trigger.status_id),
  });
