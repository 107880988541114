import React from "react";

import { useTranslation } from "next-i18next";
import { Controller, UseFormReturn } from "react-hook-form";

import { FormHelperText, MenuItem } from "@mui/material";

import { Select } from "@work4Labs/design-system";

import { Locale } from "@constants";
import { loadTranslations } from "@lib";

import { AccountParametersFormData } from "./account-parameters";

interface LocaleSelectorProps {
  methods: UseFormReturn<AccountParametersFormData>;
}
export const LocaleSelector = ({ methods }: LocaleSelectorProps) => {
  const { t } = useTranslation(["profile-settings", "common"]);
  loadTranslations("profile-settings");
  loadTranslations("common");

  return (
    <Controller
      control={methods.control}
      name="locale"
      render={({ field }) => (
        <>
          <Select
            required
            error={methods.formState.errors.locale?.message}
            selectProps={{ fullWidth: true }}
            value={field.value}
            onChange={(event) => field.onChange(event.target.value)}
            label={t("profile-settings:account.accountParameters.locale.label")}
            labelID="locale-selector"
            placeholder={t("profile-settings:account.accountParameters.locale.placeholder")}
            renderValue={(selected: AccountParametersFormData["locale"]) => t(`common:locale.${selected}`)}
          >
            {Object.values(Locale).map((locale) => (
              <MenuItem key={locale} value={locale}>
                {t(`common:locale.${locale}`)}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ marginTop: "-1rem" }}>
            {t("profile-settings:account.accountParameters.locale.helper")}
          </FormHelperText>
        </>
      )}
    />
  );
};
