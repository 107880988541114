import { useCallback, useMemo } from "react";

import { MRT_Column, MRT_ColumnDef, MRT_Header, MRT_TableInstance } from "material-react-table";
import moment from "moment";
import { useTranslation } from "next-i18next";

import { Row } from "@tanstack/react-table";

import { FilterValue, LastInteractionDateFilter, LastInteractionDateFilterFn } from "@components";
import { loadTranslations } from "@lib";
import { ApplicationListItem } from "@typings";

import { useScoringRowParams } from "@hooks";
import { useScoringEnabledQueries } from "@hooks/queries";

import {
  CompatibilityCell,
  LastInteractionDateCell,
  NameCell,
  PhoneCell,
  StatusCell,
  getApplicationName,
} from "./cells";
import { useCampaignQuestions } from "./hooks";

interface LastInteractionDateFilterFNProps {
  header: MRT_Header<ApplicationListItem>;
  column: MRT_Column<ApplicationListItem>;
  table: MRT_TableInstance<ApplicationListItem>;
}

const lastInteractionDateFilterFN = ({ header, column, table }: LastInteractionDateFilterFNProps) => {
  return (
    <LastInteractionDateFilter
      table={table}
      header={header}
      value={column.getFilterValue() as FilterValue}
      onChange={column.setFilterValue}
    />
  );
};

export const useLeadsColumns = (campaignIDs: string[], applications: ApplicationListItem[]) => {
  const { t } = useTranslation(["leads"]);
  loadTranslations("leads");

  const scoringEngineStatus = useScoringEnabledQueries(...campaignIDs);
  const { getRowParams: getRowScoreParams } = useScoringRowParams(campaignIDs);
  const applicationsQuestions = useCampaignQuestions(campaignIDs, applications);

  const anyScoringEnabled = Object.values(scoringEngineStatus).some((scoringEngineEnabled) => scoringEngineEnabled);

  const compatibilitySortingFn = useCallback(
    (a: Row<ApplicationListItem>, b: Row<ApplicationListItem>) => {
      const aRank = getRowScoreParams(a.original.id).rank;
      const bRank = getRowScoreParams(b.original.id).rank;
      return aRank - bRank;
    },
    [getRowScoreParams],
  );

  return useMemo<MRT_ColumnDef<ApplicationListItem>[]>(() => {
    const baseColumns: (MRT_ColumnDef<ApplicationListItem> | null)[] = [
      {
        id: "name",
        accessorFn: getApplicationName,
        Cell: ({ row }) => <NameCell row={row} />,
        header: t("name", { ns: "leads" }),
        columnFilterModeOptions: ["contains"],
        minSize: 130,
        enableGlobalFilter: true,
      },
      {
        id: "email",
        accessorFn: (row) => row.candidate?.email || "",
        header: t("email", { ns: "leads" }),
        columnFilterModeOptions: ["contains"],
        minSize: 130,
        enableGlobalFilter: true,
      },
      anyScoringEnabled
        ? {
            id: "compatibility",
            accessorFn: (row) => getRowScoreParams(row.id).label,
            filterFn: "arrayIncludes",
            sortingFn: compatibilitySortingFn,
            Cell: ({ row }) => <CompatibilityCell row={row} campaignID={row.original.campaign_id} />,
            header: t("compatibility", { ns: "leads" }),
            enableColumnFilter: true,
            enableGlobalFilter: false,
            minSize: 100,
          }
        : null,
      {
        id: "status",
        accessorFn: (row) => row.status,
        Cell: ({ row, cell }) => <StatusCell row={row} cell={cell} />,
        header: t(`status`, { ns: "leads" }),
        filterFn: "statusArrayIncludes",
        enableGlobalFilter: false,
        enableColumnFilter: false,
        minSize: 100,
      },
      {
        id: "phone",
        accessorFn: (row) => row.candidate?.phone ?? "",
        Cell: ({ row }) => <PhoneCell row={row} />,
        header: t(`phone`, { ns: "leads" }),
        columnFilterModeOptions: ["fuzzy"],
        minSize: 100,
        enableGlobalFilter: true,
      },
      {
        id: "job_title",
        accessorFn: (row) => row.job?.title,
        header: t(`job_title`, { ns: "leads" }),
        filterFn: "arrayIncludes",
        enableColumnFilter: false,
      },
      {
        id: "created_at",
        accessorFn: (row: ApplicationListItem) => row.created_at,
        Cell: ({ row }) => moment(row.original.created_at).format("L - LT"),
        header: t(`created_at`, { ns: "leads" }),
        enableColumnFilter: false,
        filterVariant: "datetime-range",
        minSize: 200,
      },
      {
        id: "job_location",
        accessorFn: (row) => row.job?.city,
        header: t(`job_location`, { ns: "leads" }),
        filterFn: "arrayIncludes",
        enableColumnFilter: false,
      },
      {
        id: "last_interaction_date",
        accessorFn: (row) => row.last_interaction_date && new Date(row.last_interaction_date),
        Cell: ({ row }) => <LastInteractionDateCell row={row} />,
        header: t(`last_interaction_date`, { ns: "leads" }),
        enableGlobalFilter: false,
        Filter: lastInteractionDateFilterFN,
        filterFn: LastInteractionDateFilterFn,
        minSize: 130,
      },
    ];

    applicationsQuestions.forEach((question) => {
      baseColumns.push({
        id: `question_${question.label}`,
        accessorFn: (row) => row.answers.find((answer) => answer.question_label == question.label)?.answer ?? "",
        header: question.label,
        minSize: 200,
        filterFn: "arrayIncludes",
        enableGlobalFilter: false,
      });
    });

    return baseColumns.filter((column) => column != null) as MRT_ColumnDef<ApplicationListItem>[];
  }, [anyScoringEnabled, applicationsQuestions, compatibilitySortingFn, getRowScoreParams, t]);
};
