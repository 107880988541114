import { Context } from "@opentelemetry/api";

import { AtsIntegration, AtsIntegrationUpsertion, SourcingCampaign } from "@typings";
import { withSpan, withTraceParent } from "@utils";

import { sourcingCampaignsAPI } from "./api";

const list = async (ctx: Context): Promise<Array<SourcingCampaign>> =>
  withSpan(ctx, "ListSourcingCampaigns", async () => {
    const { data } = await sourcingCampaignsAPI.get("", withTraceParent());
    return data;
  });

const get = async (ctx: Context, campaign_id: string): Promise<SourcingCampaign> =>
  withSpan(ctx, "GetSourcingCampaign", async () => {
    const { data } = await sourcingCampaignsAPI.get(`/${campaign_id}`, withTraceParent());
    return data;
  });

const upsertAtsIntegration = async (
  ctx: Context,
  campaign_id: string,
  payload: AtsIntegrationUpsertion,
): Promise<void> =>
  withSpan(ctx, "UpsertATSIntegration", async () => {
    const { data } = await sourcingCampaignsAPI.post(`/${campaign_id}/ats-integrations`, payload, withTraceParent());
    return data;
  });

const getAtsIntegration = async (ctx: Context, campaign_id: string): Promise<AtsIntegration> =>
  withSpan(ctx, "GetATSIntegration", async () => {
    const { data } = await sourcingCampaignsAPI.get(`/${campaign_id}/ats-integrations`, withTraceParent());
    return data;
  });

const deleteAtsIntegration = async (ctx: Context, campaign_id: string): Promise<void> =>
  withSpan(ctx, "DeleteATSIntegration", async () => {
    await sourcingCampaignsAPI.delete(`/${campaign_id}/ats-integrations`, withTraceParent());
  });

export const SourcingCampaignApi = {
  list,
  get,
  upsertAtsIntegration,
  getAtsIntegration,
  deleteAtsIntegration,
};
