import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "next-i18next";

import {
  ArrowDropDown as ArrowDropDownIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";
import { Box, Button, Checkbox, List, ListItem, ListItemButton, Stack, TextField, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";

import { loadTranslations } from "@lib";

import { usePromiseUpdater } from "@hooks";

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"}`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
}));

const isIterable = (obj: unknown): obj is Iterable<unknown> =>
  obj != null && typeof obj === "object" && Symbol.iterator in obj && typeof obj[Symbol.iterator] === "function";

const getNodeText = (node?: ReactNode): string => {
  if (node == null) return "";
  if (typeof node === "string") return node;
  if (typeof node === "number" || typeof node === "boolean") return `${node}`;
  if (isIterable(node)) return [...node].map(getNodeText).join("");
  if (React.isValidElement(node) && "props" in node) return getNodeText(node.props.children as ReactNode);
  return "";
};

export interface MultiSelectFilterValue {
  /**
   * The value of the current selector. This value is used for later filtering of the data.
   */
  value: string;
  /**
   * Alternative value to display in the UI. Defaults to the value.
   */
  displayValue?: ReactNode;
  /**
   * An optional count of elements that match this value. If provided, it will be displayed next to the value.
   */
  count?: number;
}

export interface MultiSelectFilterProps {
  /**
   * The title shown in the input field.
   */
  title: string;
  /**
   * The full list of allowed values.
   *
   * You can pass a function to handle large lists of values. Values will be loaded using an infinite loader. The loader
   * stops being called when the returned list is empty.
   */
  values: MultiSelectFilterValue[] | ((page: number, filter: string) => Promise<MultiSelectFilterValue[]>);
  /**
   * A list of values that are currently applied.
   */
  selected: MultiSelectFilterValue[];
  /**
   * Update selected value when new ones are applied.
   */
  onApply: (selected: MultiSelectFilterValue[]) => void;
  id?: string;
}

const defaultSearchFN = (values: MultiSelectFilterValue[], search: string) =>
  values.filter((value) =>
    (getNodeText(value.displayValue) || value.value || "").toLowerCase().includes(search.toLowerCase()),
  );

const RenderMultiSelectValue: FC<{
  option: MultiSelectFilterValue;
  pendingValue: MultiSelectFilterValue[];
  setPendingValue: (value: React.SetStateAction<MultiSelectFilterValue[]>) => void;
}> = ({ option, pendingValue, setPendingValue }) => {
  const selected = pendingValue.includes(option);
  return (
    <ListItem disablePadding sx={{ width: "100%", boxSizing: "border-box" }}>
      <ListItemButton
        sx={{ width: "100%", boxSizing: "border-box" }}
        onClick={() =>
          setPendingValue((prevValue) =>
            selected ? prevValue.filter((v) => v.value !== option.value) : [...prevValue, option],
          )
        }
      >
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        <Typography variant="body">
          {option.displayValue ?? option.value}&nbsp;{option.count != null ? `(${option.count})` : ""}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
};

/**
 * A multi-select field for filtering data in a table.
 */
export const MultiSelectFilter: FC<MultiSelectFilterProps> = ({ title, selected, values, onApply, id }) => {
  const { t } = useTranslation(["table"]);
  loadTranslations("table");

  const [allValues, setAllValues] = useState<MultiSelectFilterValue[]>([]);
  const allValuesUpdater = usePromiseUpdater(setAllValues);
  const [pendingValue, setPendingValue] = useState<MultiSelectFilterValue[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  // Pagination.
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const page = useRef(0);
  const [listRef, setListRef] = useState<HTMLDivElement | null>(null);
  const endResult = useRef(false);

  const loadNextPage = useCallback(() => {
    if (typeof values !== "function") {
      setLoading(false);
      return;
    }

    allValuesUpdater(() =>
      values(page.current, search).then((newValues) => {
        setLoading(false);

        if (newValues.length === 0) {
          endResult.current = true;
          return;
        }

        page.current += 1;
        return (prevValues) => [
          ...(prevValues ?? []),
          ...newValues.filter(
            // Prevent duplicates.
            (value) => !(prevValues ?? []).find((prevValue) => prevValue.value === value.value),
          ),
        ];
      }),
    );
  }, [search, values, allValuesUpdater]);

  // Update internal values state.
  useEffect(() => {
    setLoading(true);

    // If values is a static list, juste sync it.
    if (Array.isArray(values)) {
      page.current = 0;
      setAllValues(defaultSearchFN(values, search));
      setLoading(false);
      return;
    }

    if (listRef == null) return;

    setAllValues([]);
    page.current = 0;

    // If values is a function, call it to get the first page.
    loadNextPage();

    const onScroll = () => {
      if (listRef.scrollTop + listRef.clientHeight >= 0.9 * listRef.scrollHeight && !endResult.current) {
        setLoading(true);
        loadNextPage();
      }
    };

    listRef?.addEventListener("scroll", onScroll);

    return () => {
      listRef?.removeEventListener("scroll", onScroll);
    };
  }, [listRef, loadNextPage, search, values]);

  const opened = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    if (anchorEl) anchorEl.focus();
    setPendingValue([]);
    setAnchorEl(undefined);
  }, [anchorEl]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (opened) {
        handleClose();
        return;
      }
      setPendingValue(selected);
      setAnchorEl(event.currentTarget);
    },
    [handleClose, opened, selected],
  );

  const handleApply = useCallback(() => {
    if (anchorEl) anchorEl.focus();
    onApply(pendingValue);
    setAnchorEl(undefined);
  }, [anchorEl, onApply, pendingValue]);

  const baseID = opened ? `multiselect-filter-${id}` : undefined;

  return (
    <>
      <Button
        id={`filter_item_${id}`}
        disableRipple
        aria-describedby={baseID}
        onClick={handleClick}
        variant="outlined"
        color="primary"
      >
        <Typography
          fontSize="0.875rem"
          letterSpacing="0.0175rem"
          fontWeight={700}
          color={(theme) => theme.palette.primary.main}
        >
          {title}
        </Typography>
        <ArrowDropDownIcon />
      </Button>
      <StyledPopper id={baseID} open={opened} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Box p={(theme) => theme.spacings[8]}>
            <TextField
              size="small"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              autoFocus
              placeholder={t("multiselect.search")}
              sx={(theme) => ({
                width: "100%",
                backgroundColor: theme.palette.color.BASE[100],
              })}
            />
            <Box
              ref={setListRef}
              display="flex"
              flexDirection="column"
              boxSizing="border-box"
              width="100%"
              maxHeight="40vh"
              overflow="auto"
            >
              <List sx={{ width: "100%", boxSizing: "border-box" }}>
                {(allValues ?? []).map((option) => (
                  <RenderMultiSelectValue
                    key={option.value ?? "empty"}
                    option={option}
                    pendingValue={pendingValue}
                    setPendingValue={setPendingValue}
                  />
                ))}
                {loading ? (
                  <Typography
                    fontSize="0.8rem"
                    textAlign="center"
                    width="100%"
                    padding={(theme) => theme.spacings[8]}
                    boxSizing="border-box"
                    color={(theme) => theme.palette.color.BASE[600]}
                  >
                    {t("multiselect.loading")}
                  </Typography>
                ) : null}
                {endResult.current && typeof values === "function" && !(!allValues?.length || loading) ? (
                  <Typography
                    fontSize="0.8rem"
                    textAlign="center"
                    width="100%"
                    padding={(theme) => theme.spacings[8]}
                    boxSizing="border-box"
                    color={(theme) => theme.palette.color.BASE[800]}
                  >
                    {t("multiselect.end")}
                  </Typography>
                ) : null}
                {!allValues?.length && !loading ? (
                  <Typography
                    fontSize="0.8rem"
                    textAlign="center"
                    width="100%"
                    padding={(theme) => theme.spacings[8]}
                    boxSizing="border-box"
                    color={(theme) => theme.palette.color.BASE[800]}
                  >
                    {t("multiselect.noResults")}
                  </Typography>
                ) : null}
              </List>
            </Box>
            <Stack
              flexDirection="row"
              justifyContent="end"
              mt={(theme) => theme.spacings[8]}
              gap={(theme) => theme.spacings[8]}
            >
              <Button onClick={handleClose} variant="outlined">
                {t("multiselect.cancel")}
              </Button>
              <Button onClick={handleApply} variant="contained" color="deepPurple">
                {t("multiselect.apply")}
              </Button>
            </Stack>
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};
