import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { useSession } from "next-auth/react";
import { Trans, useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Phone, Verified } from "@mui/icons-material";
import { Box, Grid, Link, MenuItem } from "@mui/material";

import { context } from "@opentelemetry/api";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  Modal,
  SaveComponentDefault,
  SaveComponentModal,
  SaveComponentModalConfigModalProps,
  SaveComponentSuccess,
  Select,
  SelfEditablePhoneField,
  SelfEditableTextFieldEditProps,
  TextField,
} from "@work4Labs/design-system";

import { ClickToCallAPI } from "@api";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { InitiateNumberVerificationPayload, VerificationMethodEnum, VerifyNumberPayload } from "@typings";
import { Logger } from "@utils";

import { yupResolver } from "@hookform/resolvers/yup";
import { usePhoneNumberVerificationStatus } from "@hooks/queries";

import { VerifyStep, getPhoneValidationSchema } from "./utils";

const VerifyButtonContent: FC<{ children: ReactNode }> = ({ children }) => (
  <>
    <Verified />
    {children}
  </>
);

interface PhoneInputFormProps {
  verifyMethod: VerificationMethodEnum;
  setVerifyMethod: (method: VerificationMethodEnum) => void;
  verifyCode: string;
  setVerifyCode: (code: string) => void;
  verifyStep: VerifyStep;
  setVerifyStep: (step: VerifyStep) => void;
}

const PhoneInputForm: FC<PhoneInputFormProps> = ({
  verifyMethod,
  setVerifyMethod,
  verifyCode,
  setVerifyCode,
  verifyStep,
  setVerifyStep,
}) => {
  const { t } = useTranslation(["phone-number"]);
  loadTranslations("phone-number");

  if (verifyStep === "code") {
    return (
      <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Grid item xs={12}>
          <Box sx={{ fontSize: "1rem", fontWeight: "400" }}>
            <Trans
              t={t}
              i18nKey="phone-number:modal.code.content"
              components={[
                <Link
                  key="back-step"
                  sx={{ ":hover": { cursor: "pointer" } }}
                  onClick={() => setVerifyStep("method")}
                />,
              ]}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            textFieldProps={{ sx: { marginTop: "1rem" } }}
            required
            value={verifyCode}
            onChange={(event) => setVerifyCode(event.target.value)}
            placeholder={t("phone-number:modal.code.placeholder")}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Grid item xs={12}>
        <Box sx={{ fontSize: "1rem", fontWeight: "400" }}>{t("phone-number:modal.method.content")}</Box>
      </Grid>
      <Grid item xs={12}>
        <Select
          selectProps={{ sx: { marginTop: "1rem" } }}
          required
          onChange={(e) => setVerifyMethod(e.target.value as VerificationMethodEnum)}
          value={verifyMethod}
          renderValue={(selected) => t(`phone-number:modal.method.options.${selected}`)}
        >
          {Object.values(VerificationMethodEnum).map((method) => (
            <MenuItem key={method} value={method}>
              {t(`phone-number:modal.method.options.${method}`)}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

interface VerifyPhoneModalProps {
  onSubmit: (data: { [p: string]: string }) => void;
  phoneValue: string;
  name: string;
}

const VerifyPhoneModal: FC<SaveComponentModalConfigModalProps & VerifyPhoneModalProps> = ({
  isOpen,
  closeModal,
  onSubmit,
  phoneValue,
  name,
}) => {
  const { t } = useTranslation(["phone-number"]);
  loadTranslations("phone-number");

  const [verifyStep, setVerifyStep] = useState<VerifyStep>("method");
  const [verifyMethod, setVerifyMethod] = useState<VerificationMethodEnum>(VerificationMethodEnum.SMS);
  const [verifyCode, setVerifyCode] = useState("");

  const queryClient = useQueryClient();
  const { data: session } = useSession();

  const { mutate: initiateVerification, ...initiateVerificationMutation } = useMutation({
    mutationFn: (data: InitiateNumberVerificationPayload) =>
      ClickToCallAPI.initiateNumberVerification(context.active(), data),
    onSuccess: () => {
      toast.success(t("phone-number:modal.method.call.success"));
      setVerifyStep("code");
    },
    onError: () => {
      toast.error(t("phone-number:modal.method.call.error"));
    },
  });

  const { mutate: verifyNumber, ...verifyNumberMutation } = useMutation({
    mutationFn: (data: VerifyNumberPayload) => ClickToCallAPI.verifyNumber(context.active(), data),
    onSuccess: () => {
      toast.success(t("phone-number:modal.code.call.error"));

      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.CLICK_TO_CALL_NUMBER_VERIFICATION_STATUS, session?.user.phone],
        })
        .catch(Logger.error);

      onSubmit({ [name]: phoneValue });

      setVerifyStep("method");
      setVerifyMethod(VerificationMethodEnum.SMS);
      setVerifyCode("");
      closeModal();
    },
    onError: () => {
      toast.error(t("phone-number:modal.code.call.error"));
    },
  });

  const onConfirm = useCallback(() => {
    switch (verifyStep) {
      case "method":
        initiateVerification({ method: verifyMethod, phone_number: phoneValue });
        break;
      case "code":
        verifyNumber({ phone_number: phoneValue, verification_code: verifyCode });
        break;
    }
  }, [initiateVerification, phoneValue, verifyCode, verifyMethod, verifyNumber, verifyStep]);

  const onCloser = useCallback(() => {
    setVerifyStep("method");
    setVerifyMethod(VerificationMethodEnum.SMS);
    setVerifyCode("");
    closeModal();
  }, [closeModal]);

  return (
    <Modal
      options={{
        confirmProps: {
          disabled: initiateVerificationMutation.isPending || verifyNumberMutation.isPending,
        },
      }}
      cancelText={verifyStep === "code" ? t("phone-number:modal.code.cancel") : undefined}
      confirmText={t(`phone-number:modal.${verifyStep}.confirm`)}
      onConfirm={onConfirm}
      onClose={onCloser}
      modalIcon={<Phone />}
      modalTitle={t(`phone-number:modal.${verifyStep}.modalTitle`)}
      title={t(`phone-number:modal.${verifyStep}.title`)}
      isOpen={isOpen}
    >
      <PhoneInputForm
        verifyCode={verifyCode}
        verifyMethod={verifyMethod}
        verifyStep={verifyStep}
        setVerifyCode={setVerifyCode}
        setVerifyMethod={setVerifyMethod}
        setVerifyStep={setVerifyStep}
      />
    </Modal>
  );
};

interface PhoneFormSaveComponentProps {
  forceValidation?: boolean;
  customSuccessButton?: PhoneInputProps["customSuccessButton"];
  onSubmit: (data: { phone: string }) => void;
  phoneValue: string;
  phoneValid: boolean;
}

const SaveComponent = ({
  forceValidation,
  customSuccessButton,
  name,
  onSubmit,
  phoneValue,
  phoneValid,
  isEditing,
  ...inputProps
}: PhoneFormSaveComponentProps & SelfEditableTextFieldEditProps) => {
  const { t } = useTranslation(["phone-number"]);
  loadTranslations("phone-number");

  const phoneNumberVerificationStatus = usePhoneNumberVerificationStatus(forceValidation ? phoneValue : "");

  const modalComponent = useCallback(
    ({ isOpen, closeModal }: SaveComponentModalConfigModalProps) => (
      <VerifyPhoneModal
        isOpen={isOpen}
        closeModal={closeModal}
        phoneValue={phoneValue}
        name={name}
        onSubmit={onSubmit}
      />
    ),
    [name, onSubmit, phoneValue],
  );

  const modalWrapperComponent = useCallback(
    (props: SelfEditableTextFieldEditProps) => (
      <SaveComponentModal disabled={!phoneValid} modal={modalComponent} {...props}>
        <VerifyButtonContent>{t("phone-number:verify")}</VerifyButtonContent>
      </SaveComponentModal>
    ),
    [modalComponent, t, phoneValid],
  );

  if (!forceValidation || (isEditing && phoneNumberVerificationStatus.verified)) {
    return (
      <SaveComponentDefault isEditing={isEditing} disabled={!phoneValid} name={name} {...inputProps}>
        {t("phone-number:save")}
      </SaveComponentDefault>
    );
  }

  return (
    <SaveComponentSuccess
      name={name}
      isEditing={isEditing}
      initial={modalWrapperComponent}
      onClick={customSuccessButton?.action}
      success={phoneNumberVerificationStatus.verified}
      sx={{ pointerEvents: customSuccessButton?.action ? undefined : "none" }}
      {...inputProps}
    >
      {customSuccessButton?.children ?? <VerifyButtonContent>{t("phone-number:verified")}</VerifyButtonContent>}
    </SaveComponentSuccess>
  );
};

export interface PhoneInputProps {
  loading?: boolean;
  disabled?: boolean;
  defaultValue: string;
  name: string;
  onSubmit: (data: { [p: string]: string }) => void;
  label?: ReactNode;
  labelHelper?: ReactNode;
  forceValidation?: boolean;
  customSuccessButton?: {
    children?: ReactNode;
    action?: () => void;
  };
  placeholder?: string;
  required?: boolean;
  forceFocus?: boolean;
}

export const PhoneInput: FC<PhoneInputProps> = ({
  loading,
  name,
  label,
  labelHelper,
  disabled,
  onSubmit,
  defaultValue,
  forceValidation,
  customSuccessButton,
  placeholder,
  required,
  forceFocus,
}) => {
  const { t, i18n } = useTranslation(["phone-number"]);
  loadTranslations("phone-number");

  const [editPhone, setEditPhone] = useState(forceFocus ?? false);

  const phoneValidationSchema = getPhoneValidationSchema(t, name);

  const phoneForm = useForm({
    shouldUnregister: false,
    resolver: yupResolver(phoneValidationSchema),
    defaultValues: { [name]: defaultValue },
    mode: "onChange",
  });

  const {
    setValue,
    getValues,
    formState: { isValid },
  } = phoneForm;

  // Required to update the phone value, when data has not loaded yet.
  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);

  // This is the value of the language to be used on the phone input
  // based on the user language.
  // PS: if we add new languages with custom code (e.g: en-US, en-UK)
  // we need to add their countries here.
  const language = useMemo(() => {
    switch (i18n.language) {
      case "en-US":
        return "en";
      default:
        return i18n.language;
    }
  }, [i18n.language]);

  const saveComponent = useCallback(
    (props: SelfEditableTextFieldEditProps) => (
      <SaveComponent
        forceValidation={forceValidation}
        customSuccessButton={customSuccessButton}
        onSubmit={onSubmit}
        phoneValue={getValues(name)}
        phoneValid={isValid}
        {...props}
      />
    ),
    [forceValidation, customSuccessButton, onSubmit, getValues, name, isValid],
  );

  return (
    <SelfEditablePhoneField
      submitHandler={(data) => {
        onSubmit(data);
        setEditPhone(false);
      }}
      methods={phoneForm}
      disabled={disabled || loading}
      name={name}
      lang={language}
      isEditing={editPhone}
      setIsEditing={setEditPhone}
      saveComponent={saveComponent}
      placeholder={placeholder}
      label={label}
      labelHelper={labelHelper}
      required={required}
    />
  );
};
