import { FC, useCallback, useEffect, useState } from "react";

import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Box, Button, Card, FormControlLabel, Grid, Switch, TextField, Typography } from "@mui/material";

import { context } from "@opentelemetry/api";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { InterviewApi } from "@api";
import { Form, FormSelect, FormSwitch, Spinner } from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { InterviewMessageType, UpsertInterviewsConfigurationParams } from "@typings";

import { useConfirm } from "@hooks";
import { useInterviewConfigurationByCampaignID } from "@hooks/queries";

const DURATION_OPTIONS = [
  { label: "no_prefill", value: 0 },
  { label: "30_minutes", value: 30 },
  { label: "45_minutes", value: 45 },
  { label: "1_hour", value: 60 },
  { label: "1_hour_30_minutes", value: 90 },
  { label: "2_hours", value: 120 },
];

const MESSAGE_TYPES_DEFAULT_VALUES = Object.keys(InterviewMessageType).reduce(
  (acc, key) => {
    acc[InterviewMessageType[key]] = true;
    return acc;
  },
  {} as Record<InterviewMessageType, boolean>,
);

type Props = {
  campaignId: string;
};

type FormData = {
  duration: number;
  additional_information: string;
  location: string;
  messages: Record<InterviewMessageType, boolean>;
};

export const InterviewConfigurationTab: FC<Props> = ({ campaignId }) => {
  const { t } = useTranslation(["interview-configuration"]);
  loadTranslations("interview-configuration");

  const [interviewEnabled, setInterviewEnabled] = useState(false);
  const [DeleteConfigurationDialog, confirmDeleteConfiguration] = useConfirm({
    title: t("disable_modal.title"),
    modalTitle: t("disable_modal.sub_title"),
  });

  const queryClient = useQueryClient();

  const methods = useForm<FormData>({
    defaultValues: {
      duration: 30,
      additional_information: "",
      location: "",
      messages: MESSAGE_TYPES_DEFAULT_VALUES,
    },
  });

  const { reset, getValues, register, setValue } = methods;

  const { data: interviewsConfiguration, isLoading } = useInterviewConfigurationByCampaignID(campaignId);

  useEffect(() => {
    if (interviewsConfiguration) {
      // change the data to be compatible with the form (override default values)
      setValue("duration", interviewsConfiguration.duration);
      setValue("additional_information", interviewsConfiguration.additional_information);
      setValue("location", interviewsConfiguration.location);
      setValue(
        "messages",
        Object.keys(InterviewMessageType).reduce(
          (acc, key) => {
            acc[InterviewMessageType[key]] = interviewsConfiguration.messages.includes(
              InterviewMessageType[key] as string,
            );
            return acc;
          },
          {} as Record<InterviewMessageType, boolean>,
        ),
      );

      setInterviewEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewsConfiguration]);

  const { mutate: deleteConfiguration } = useMutation({
    mutationFn: (campaignID: string) => InterviewApi.deleteConfiguration(context.active(), campaignID),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.INTERVIEW_CONFIGURATION, campaignId],
        })
        .catch(() => {});
      reset();
      setInterviewEnabled(false);
      toast.success(t("delete_success"));
    },
    onError: () => {
      toast.error(t("error_occurred"));
    },
  });

  const { mutate: upsertConfiguration } = useMutation({
    mutationFn: (data: UpsertInterviewsConfigurationParams) => InterviewApi.upsertConfiguration(context.active(), data),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.INTERVIEW_CONFIGURATION, campaignId],
        })
        .catch(() => {});
      toast.success(t("upsert_success"));
    },
    onError: () => {
      toast.error(t("error_occurred"));
    },
  });

  const handleUpsert = useCallback(
    (data: FormData) => {
      const payload = {
        location: data.location,
        duration: data.duration,
        additional_information: data.additional_information,
        messages: Object.keys(data.messages).filter((key) => data.messages[key]),
      };

      upsertConfiguration({ campaign_id: campaignId, payload });
    },
    [campaignId, upsertConfiguration],
  );

  const handleActivation = useCallback(
    (interviewEnabled: boolean) => {
      const confirmDelete = async () => {
        return await confirmDeleteConfiguration();
      };

      if (!interviewEnabled) {
        confirmDelete().then((ans) => {
          if (ans) {
            deleteConfiguration(campaignId);
          }
        });
      } else {
        const values = getValues();

        handleUpsert(values);
      }
    },
    [campaignId, confirmDeleteConfiguration, deleteConfiguration, getValues, handleUpsert],
  );

  if (isLoading) {
    return <Spinner style={{ position: "relative" }} />;
  }

  return (
    <>
      <Form methods={methods} submitHandler={handleUpsert}>
        <Box display="flex" flexDirection="column" sx={{ gap: "1.5rem" }}>
          <Card sx={{ padding: "2rem" }}>
            <Grid container>
              <Grid item xs={4}>
                <Typography color="primary" fontSize="1.5rem" fontWeight="600" marginBottom="0.875rem">
                  {t("feature_activation.title")}
                </Typography>
                <Typography color={(theme) => theme.palette.grey[600]} fontSize="0.875rem">
                  {t("feature_activation.description")}
                </Typography>
              </Grid>
              <Grid item xs={8} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <FormControlLabel
                  label={t("feature_activation.form.enable")}
                  labelPlacement="start"
                  control={<Switch />}
                  componentsProps={{ typography: { fontWeight: "bold", color: "primary" } }}
                  sx={{ marginLeft: 0 }}
                  onChange={(e, checked) => handleActivation(checked)}
                  checked={interviewEnabled}
                />
              </Grid>
            </Grid>
          </Card>
          {interviewEnabled && (
            <>
              <Card sx={{ padding: "2rem" }}>
                <Grid container spacing="2rem">
                  <Grid item xs={4}>
                    <Typography color="primary" fontSize="1.5rem" fontWeight="600" marginBottom="0.875rem">
                      {t("message_automation.title")}
                    </Typography>
                    <Typography color={(theme) => theme.palette.grey[600]} fontSize="0.875rem" marginBottom="0.875rem">
                      {t("message_automation.description_1")}
                    </Typography>
                    <Typography color={(theme) => theme.palette.grey[600]} fontSize="0.875rem">
                      {t("message_automation.description_2")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} display="flex" flexDirection="column" alignItems="start" sx={{ gap: "2rem" }}>
                    <Box width="100%">
                      <Typography color="primary" fontWeight="bold" marginBottom="0.5rem">
                        {t("message_automation.email_to_candidates")}
                      </Typography>
                      <FormSwitch
                        name={`messages.${InterviewMessageType.EMAIL_CONFIRMATION_CANDIDATES}`}
                        label={t("message_automation.form.interview_confirmation")}
                        labelPlacement="start"
                        formControlLabelProps={{
                          sx: { marginLeft: 0, width: "100%", justifyContent: "space-between" },
                        }}
                      />
                      <FormSwitch
                        name={`messages.${InterviewMessageType.EMAIL_REMINDER_48H_CANDIDATES}`}
                        label={t("message_automation.form.candidate_email_48h_reminder")}
                        labelPlacement="start"
                        formControlLabelProps={{
                          sx: { marginLeft: 0, width: "100%", justifyContent: "space-between" },
                        }}
                      />
                    </Box>
                    <Box width="100%">
                      <Typography color="primary" fontWeight="bold" marginBottom="0.5rem">
                        {t("message_automation.email_to_recruiters")}
                      </Typography>
                      <FormSwitch
                        name={`messages.${InterviewMessageType.EMAIL_CONFIRMATION_RECRUITERS}`}
                        label={t("message_automation.form.interview_confirmation")}
                        labelPlacement="start"
                        formControlLabelProps={{
                          sx: { marginLeft: 0, width: "100%", justifyContent: "space-between" },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography color="primary" fontWeight="bold" marginBottom="0.5rem">
                      {t("message_automation.sms_to_candidates")}
                    </Typography>
                    <Box display="flex" flexDirection="column" alignItems="start" sx={{ gap: "0.375rem" }}>
                      <FormSwitch
                        name={`messages.${InterviewMessageType.SMS_CONFIRMATION_CANDIDATES}`}
                        label={t("message_automation.form.interview_confirmation")}
                        labelPlacement="start"
                        formControlLabelProps={{
                          sx: { marginLeft: 0, width: "100%", justifyContent: "space-between" },
                        }}
                      />
                      <FormSwitch
                        name={`messages.${InterviewMessageType.SMS_REMINDER_72H_CANDIDATES}`}
                        label={t("message_automation.form.72h_reminder")}
                        labelPlacement="start"
                        formControlLabelProps={{
                          sx: { marginLeft: 0, width: "100%", justifyContent: "space-between" },
                        }}
                      />
                      <FormSwitch
                        name={`messages.${InterviewMessageType.SMS_REMINDER_24H_CANDIDATES}`}
                        label={t("message_automation.form.24h_reminder")}
                        labelPlacement="start"
                        formControlLabelProps={{
                          sx: { marginLeft: 0, width: "100%", justifyContent: "space-between" },
                        }}
                      />
                      <FormSwitch
                        name={`messages.${InterviewMessageType.SMS_REMINDER_DDAY_CANDIDATES}`}
                        label={t("message_automation.form.dday_reminder")}
                        labelPlacement="start"
                        formControlLabelProps={{
                          sx: { marginLeft: 0, width: "100%", justifyContent: "space-between" },
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Card>
              <Card sx={{ padding: "2rem" }}>
                <Grid container spacing="2rem">
                  <Grid item xs={4}>
                    <Typography color="primary" fontSize="1.5rem" fontWeight="600" marginBottom="0.875rem">
                      {t("customization.title")}
                    </Typography>
                    <Typography color={(theme) => theme.palette.grey[600]} fontSize="0.875rem" marginBottom="0.875rem">
                      {t("customization.description")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} display="flex" flexDirection="column" justifyContent="space-between">
                    <Box>
                      <FormSelect
                        name="duration"
                        fullWidth
                        required
                        label={t("customization.form.duration.label")}
                        options={DURATION_OPTIONS.map((o) => ({
                          ...o,
                          label: t(`customization.form.duration.options.${o.label}`),
                        }))}
                      />
                    </Box>
                    <Box>
                      <Typography color="primary" fontWeight="bold" marginBottom="0.5rem">
                        {t("customization.form.location")}
                      </Typography>
                      <TextField {...register("location")} multiline fullWidth rows={2} />
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography color="primary" fontWeight="bold" marginBottom="0.5rem">
                      {t("customization.form.additional_information.label")}
                    </Typography>
                    <TextField
                      {...register("additional_information")}
                      multiline
                      placeholder={t("customization.form.additional_information.placeholder")}
                      fullWidth
                      rows={10}
                    />
                  </Grid>
                </Grid>
                <Button variant="contained" color="primary" type="submit" sx={{ float: "right", mt: "1rem" }}>
                  {t("submit")}
                </Button>
              </Card>
            </>
          )}
        </Box>
      </Form>
      <DeleteConfigurationDialog>
        <Box display="flex" flexDirection="column" gap="1.5rem">
          <Typography color={(theme) => theme.palette.grey[600]}>{t("disable_modal.description_1")}</Typography>
          <Typography color={(theme) => theme.palette.grey[600]} fontWeight="600">
            {t("disable_modal.description_2")}
          </Typography>
        </Box>
      </DeleteConfigurationDialog>
    </>
  );
};
