import { FC } from "react";

import { MRT_Row } from "material-react-table";
import { useTranslation } from "next-i18next";

import { Warning as WarningIcon } from "@mui/icons-material";
import { Avatar, Box, Stack, Tooltip, Typography } from "@mui/material";

import { useQuery } from "@tanstack/react-query";

import { withThemes } from "@work4Labs/design-system";

import { JobsApi } from "@api";
import { LoadingAnimation, UserAvatar } from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { AuthProxyUsersListItem, ListJobItem } from "@typings";

import { useOTELContext, useUserGroup } from "@hooks";
import { useOrganizationUsersObject } from "@hooks/queries";

export const ResponsiblesCell: FC<{ row: MRT_Row<ListJobItem> }> = ({ row }) => {
  return <JobResponsibles jobID={row.original.id} />;
};

export const JobResponsibles: FC<{ jobID: string }> = ({ jobID }) => {
  const { t } = useTranslation(["jobs-list"]);
  loadTranslations("jobs-list");

  const ctx = useOTELContext();

  const userGroup = useUserGroup();

  const jobAssigneesQuery = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.JOB_ASSIGNEES, jobID],
    queryFn: () => JobsApi.listJobAssignees(ctx, jobID),
  });

  const usersQuery = useOrganizationUsersObject(userGroup);

  if (
    jobAssigneesQuery.isLoading ||
    jobAssigneesQuery.isError ||
    !jobAssigneesQuery.isSuccess ||
    usersQuery.isLoading ||
    usersQuery.isError ||
    !usersQuery.isSuccess
  ) {
    return <LoadingAnimation />;
  }

  if (jobAssigneesQuery.data.length === 0) {
    return (
      <Box display="flex" flexDirection="row" alignItems="center" gap={(theme) => theme.spacings[8]}>
        <Typography
          fontSize="0.8rem"
          fontWeight={700}
          lineHeight="150%"
          color={(theme) => theme.palette.color.ALERT_RED[400]}
        >
          {t("columns.responsibles.none")}
        </Typography>
        <WarningIcon color="error" />
      </Box>
    );
  }

  return (
    <Tooltip title={<ResponsiblesCellTooltipContent users={usersQuery.data} assignees={jobAssigneesQuery.data} />}>
      <Stack id="job-responsibles" display="inline-flex" flexDirection="row" flexWrap="nowrap" gap={0}>
        {jobAssigneesQuery.data
          .filter((assigneeID) => !!usersQuery.data[assigneeID])
          .slice(0, 5)
          .map((assigneeID, idx) => (
            <UserAvatar
              key={assigneeID}
              userName={`${usersQuery.data[assigneeID].first_name} ${usersQuery.data[assigneeID].last_name}`}
              size="36px"
              sx={{ flexShrink: 0, transform: idx > 0 ? `translateX(calc(-0.5rem*${idx}))` : "none" }}
            />
          ))}
        {jobAssigneesQuery.data.length > 5 && (
          <Avatar
            sx={withThemes({
              width: "36px",
              height: "36px",
              fontSize: "calc(0.5 * 36px)",
              transform: "translateX(calc(-0.5rem*5))",
            })}
          >
            {`+${jobAssigneesQuery.data.length - 5}`}
          </Avatar>
        )}
      </Stack>
    </Tooltip>
  );
};

interface ResponsiblesCellTooltipContentProps {
  users: Record<string, AuthProxyUsersListItem>;
  assignees: string[];
}

export const ResponsiblesCellTooltipContent: FC<ResponsiblesCellTooltipContentProps> = ({ users, assignees }) => {
  return (
    <Box display="flex" flexDirection="column" gap={(theme) => theme.spacings[8]}>
      {assignees.map((assigneeID) => (
        <Box key={assigneeID} display="flex" flexDirection="row" alignItems="center" gap={(theme) => theme.spacings[8]}>
          <UserAvatar
            userName={`${users[assigneeID].first_name} ${users[assigneeID].last_name}`}
            size="36px"
            sx={{ flexShrink: 0 }}
          />
          <Typography variant="tagsStrong" color={(theme) => theme.palette.text.mainInfo}>
            {`${users[assigneeID].first_name} ${users[assigneeID].last_name}`}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
