import { styled } from "@mui/material/styles";

export const HeaderContent = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 37px 16px 40px;
  gap: 111px;
  background: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
`;
