import { useEffect, useMemo } from "react";

import { Context, context } from "@opentelemetry/api";

import { SpanAndContext, startSpan } from "@utils";

export const useOTELSpan = (ctx: Context, name: string): SpanAndContext => {
  const { span, ctx: context } = useMemo(() => startSpan(ctx, name), [ctx, name]);

  useEffect(() => {
    return () => {
      span.end();
    };
  }, [span]);

  return { span, ctx: context };
};

export const useOTELContext = (ctx?: Context) => useMemo(() => ctx ?? context.active(), [ctx]);
