import { useRouter } from "next/router";

import { FC, KeyboardEventHandler, ReactNode, SyntheticEvent, useEffect, useRef, useState } from "react";

import {
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";

import { IMenuItem } from "@typings";
import { Logger } from "@utils";

export interface ActiveMenuButtonProps {
  items: IMenuItem[];
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  active: boolean;
}

export const ActiveMenuButton: FC<ActiveMenuButtonProps> = ({ items, header, children, footer, active }) => {
  const { push } = useRouter();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: Event | SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown: KeyboardEventHandler<HTMLUListElement> = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box
      sx={{
        display: "contents",
        borderRadius: "0 16px 16px 0",
        backgroundColor: active ? theme.palette.action.selected : "",
      }}
    >
      {active && (
        <Box
          component="span"
          sx={{
            width: "5px",
            borderRadius: "0 16px 16px 0",
            height: "100%",
            position: "absolute",
            left: 0,
            backgroundColor: theme.palette.text.primary,
          }}
        ></Box>
      )}
      <Button
        ref={anchorRef}
        id="composition-button"
        data-testid="composition-button"
        aria-controls={open ? "composition-menu" : ""}
        aria-expanded={open ? "true" : "false"}
        aria-haspopup="true"
        onClick={handleToggle}
        fullWidth
        sx={{
          backgroundColor: "transparent",
          border: "none",
          "&:hover": {
            backgroundColor: "transparent",
            border: "none",
          },
        }}
      >
        {children}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="right-start"
        transition
        sx={{ zIndex: "1" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "right-start" ? "left top" : "left bottom",
            }}
          >
            <Paper sx={{ borderRadius: "1rem", marginLeft: "1rem", padding: "1rem", width: "172px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {header && (
                    // div is required by MUI
                    <div>
                      {header}
                      <Divider />
                    </div>
                  )}
                  {items.map((item) => (
                    <MenuItem
                      key={item.label}
                      sx={{ paddingLeft: "6px" }}
                      onClick={(e) => {
                        if (item.type === "link") {
                          push(item.link).catch(Logger.error);
                        } else if (item.type === "button") {
                          item.action();
                        }

                        handleClose(e);
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <item.icon
                          sx={(theme) => ({
                            width: "20px",
                            color: theme.palette.text.primary,
                            height: "20px",
                          })}
                        />
                        <Typography
                          sx={(theme) => ({
                            paddingLeft: "10px",
                            fontSize: "12px",
                            width: "100%",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: theme.palette.text.primary,
                            display: "flex",
                            alignItems: "center",
                          })}
                        >
                          {" "}
                          {item.label}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                  {footer && (
                    <>
                      <Divider />
                      {footer}
                    </>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
