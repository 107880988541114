enum ApplicationPermissions {
  Delete = "applications:delete",
}

enum SourcingCampaignsPermissions {
  Write = "sourcing-campaigns:write",
}

enum UsersPermissions {
  Write = "users:write",
}

enum OrganizationsPermissions {
  Write = "organizations:write",
}

enum ScoringPermissions {
  Write = "scoring.scores:write",
}

enum JobsPermissions {
  Delete = "jobs:delete",
  WriteAssignations = "jobs.assignations:write",
  DeleteAssignations = "jobs.assignations:delete",
}

export const UserPermissions = {
  ApplicationPermissions,
  SourcingCampaignsPermissions,
  UsersPermissions,
  OrganizationsPermissions,
  ScoringPermissions,
  JobsPermissions,
};
